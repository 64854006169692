import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDialog } from "hooks/useDialog";
import { PrimaryDialog } from "../PrimaryDialog";
import { Button } from "../Button";
import { MainInputInput } from "../MainInput";
import styles from "./PasswordDialog.module.scss";

export const PasswordDialog = ({ version, onVersionMismatch }) => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const passwordDialog = useDialog();

  const handlePasswordChange = event => {
    setError(false);
    setPassword(event.target.value);
  };

  const handlePasswordSubmit = () => {
    const hostname = window.location.hostname;
    const correctPassword = hostname.includes("dodemo.online")
      ? "topsecret"
      : hostname.includes("www.pillarhousesales.com")
      ? "PillarKey201#"
      : hostname.includes("localhost")
      ? "localsecret"
      : null;

    if (password === correctPassword) {
      localStorage.setItem("password", password);
      passwordDialog.closeDialog();
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (window.location.protocol === "file:") {
      return;
    }

    const storedVersion = localStorage.getItem("appVersion");
    const hostname = window.location.hostname;
    const storedPassword = localStorage.getItem("password");
    const correctPassword = hostname.includes("dodemo.online")
      ? "topsecret"
      : hostname.includes("www.pillarhousesales.com")
      ? "PillarKey201#"
      : hostname.includes("localhost")
      ? "localsecret"
      : null;

    if (storedVersion !== version) {
      onVersionMismatch();
      localStorage.setItem("appVersion", version);
    }

    if (storedPassword !== correctPassword) {
      passwordDialog.openDialog();
    }
  }, [version, passwordDialog, onVersionMismatch]);

  useEffect(() => {
    const checkPasswordDialogOpen = () => {
      if (passwordDialog.isOpen) {
        navigate("/general");
      }
    };

    checkPasswordDialogOpen();
  }, [passwordDialog.isOpen, navigate]);

  return (
    <PrimaryDialog
      mode="dark"
      size="sm"
      radius="radius-16"
      blurBackdrop
      open={passwordDialog.isOpen}
      onClose={() => {}}
      className={styles.dialogContainer}
    >
      <div>
        <h2>Enter Password</h2>
        <MainInputInput
          placeholder="Password"
          type="password"
          value={password}
          onEnter={handlePasswordSubmit}
          onChange={handlePasswordChange}
          error={error}
          hasErrorIcon
          helperText={error ? "Incorrect password." : ""}
        />
        <Button className={styles.submitBtn} onClick={handlePasswordSubmit}>
          OK
        </Button>
      </div>
    </PrimaryDialog>
  );
};
