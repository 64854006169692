const IMAGE_BASE_URL =
  process.env.REACT_APP_IMAGE_BASE_URL || "/assets/images/";
const VIDEO_BASE_URL =
  process.env.REACT_APP_VIDEO_BASE_URL || "/assets/videos/";

// Product Images
export const PRODUCT_IMAGES = {
  Fusion4Product: `${IMAGE_BASE_URL}products/fusion-4.png`,
  JadeS200MK2Product: `${IMAGE_BASE_URL}products/jade-s-200-mk2.png`,
  Pilot: `${IMAGE_BASE_URL}products/pilot.png`,
  JadeMK2: `${IMAGE_BASE_URL}products/jade-mk2.png`,
  QuadronPLC: `${IMAGE_BASE_URL}products/quadron-plc.png`,
  JadeMK4: `${IMAGE_BASE_URL}products/jade-mk4.png`,
  JadePro: `${IMAGE_BASE_URL}products/jade-pro.png`,
  JadeProDex: `${IMAGE_BASE_URL}products/jade-prodex.png`,
};

// Enhancement Videos and Images
export const ENHANCEMENTS = {
  FluxMonitor2: `${VIDEO_BASE_URL}enhancements/Optical Flux Presence Sensor.mp4`,
  FluxFlowMeter: `${IMAGE_BASE_URL}enhancements/flux_flow_meter.jpg`,
  Fiducial: `${IMAGE_BASE_URL}enhancements/Fiducial.JPG`,
  MagneticPumpPng: `${IMAGE_BASE_URL}enhancements/Magnetic Pump.png`,
  AOIAutomaticImage: `${IMAGE_BASE_URL}enhancements/AOI-Automatic.png`,
};

// Videos
export const ENHANCEMENT_VIDEOS = {
  BoardWarpVideo: `${VIDEO_BASE_URL}enhancements/boardwarp.mp4`,
  TwinBathVideo: `${VIDEO_BASE_URL}enhancements/TwinBath.mp4`,
  AutoNozzleConditioningVideo: `${VIDEO_BASE_URL}enhancements/Auto Nozzle Conditioning.mp4`,
  FeederVideo: `${VIDEO_BASE_URL}enhancements/Feeder short.mp4`,
};

export const EXISTING_KEYS = {
  Synchrodex: "Synchrodex",
  "Orissa Synchrodex": "Orissa Synchrodex",
  "Orissa Synchrodex Pro": "Orissa Synchrodex Pro",
  "Fusion Layouts": "Fusion Layouts",
};

export const EXISTING_ENHANCEMENTS_KEYS = {
  fluxing: "fluxing",
  soldering: "soldering",
  other: "other",
};

export const FEATURES = {
  default: [
    {
      xPos: 262,
      yPos: 85,
      text: "IPC-2591 (CFX), Smart Factory ready",
    },
    {
      xPos: 189,
      yPos: 212,
      text: "Colour programming camera",
    },
    {
      xPos: 261,
      yPos: 330,
      text: "Drop Jet fluxer",
    },
    {
      xPos: 160,
      yPos: 454,
      text: "Flux level sensor",
    },
    {
      xPos: 305,
      yPos: 537,
      text: "Multiple level password protection",
    },
    {
      xPos: "30%",
      yPos: 627,
      text: "508mm x 508mm (20' x 20') and 610mm x 610mm (24' x 24') board handling sizes",
    },
    {
      xPos: 901,
      yPos: 512,
      text: "SMEMA compatible",
    },
    {
      xPos: 958,
      yPos: 396,
      text: "New PillarCOMM control software",
    },
    {
      xPos: 920,
      yPos: 250,
      text: "Internal fume extraction",
    },
    {
      xPos: 1075,
      yPos: 165,
      text: "Day-to-day service kit",
    },
    {
      xPos: 964,
      yPos: 80,
      text: "DC drives on all axes",
    },
  ],
  "Orissa Synchrodex": [
    {
      xPos: 162,
      yPos: 85,
      text: "In-line motor driven auto width adjust through feed synchronous movement conveyor",
    },
    {
      xPos: 189,
      yPos: 212,
      text: "Conveyor side clamping",
    },
    {
      xPos: 201,
      yPos: 330,
      text: "Integral PC and machine mounted monitor",
    },
    {
      xPos: 160,
      yPos: 454,
      text: "Motorised auto-solder top-up (wire feed) & solder level detect",
    },
    {
      xPos: 305,
      yPos: 537,
      text: "Heated inerted Nitrogen system",
    },
    {
      xPos: "30%",
      yPos: 637,
      text: "Titanium Drop-Jet fluxer",
    },
    {
      xPos: 901,
      yPos: 512,
      text: "Set of AP solder nozzle tips",
    },
    {
      xPos: 958,
      yPos: 396,
      text: "Internal fume extraction",
    },
    {
      xPos: 920,
      yPos: 250,
      text: "Colour programming camera",
    },
    {
      xPos: 1075,
      yPos: 185,
      text: "Solder wave height measurement and correction",
    },
    {
      xPos: 964,
      yPos: 80,
      text: "Pump rpm",
    },
    {
      xPos: 62,
      yPos: 15,
      text: "Auto-nozzle conditioning system",
    },
    {
      xPos: 139,
      yPos: 142,
      text: "Thermal nozzle calibration system using integrated setting camera",
    },
    {
      xPos: 111,
      yPos: 270,
      text: "Windows® based PillarCOMM ‘Point & Click’ interface",
    },
    {
      xPos: 130,
      yPos: 404,
      text: "Light stack",
    },
    {
      xPos: 80,
      yPos: 587,
      text: "Auto fiducial recognition and correction system",
    },
    {
      xPos: 80,
      yPos: 647,
      text: "Flux level sensor",
    },
    {
      xPos: 901,
      yPos: 602,
      text: "Multiple level password protection",
    },
    {
      xPos: 988,
      yPos: 456,
      text: "PillarPAD offline programming system",
    },
    {
      xPos: 990,
      yPos: 320,
      text: "SMEMA compatible",
    },
    {
      xPos: 775,
      yPos: 135,
      text: "Process viewing camera with record feature",
    },
    {
      xPos: 1164,
      yPos: 80,
      text: "Lead-free capability",
    },
    {
      xPos: 964,
      yPos: 15,
      text: "Day-to-day service kit",
    },
  ],
  "Orissa Synchrodex Pro": [
    {
      xPos: 162,
      yPos: 85,
      text: "508mm x 508mm (20' x 20') and 610mm x 610mm (24' x 24') board handling sizes",
    },
    {
      xPos: 189,
      yPos: 212,
      text: "Interchangeable Mechanical or Magnetic solder bath and pump",
    },
    {
      xPos: 201,
      yPos: 330,
      text: "Lead-free solder capability",
    },
    {
      xPos: 160,
      yPos: 454,
      text: "Inerted Nitrogen system",
    },
    {
      xPos: 305,
      yPos: 537,
      text: "Set of AP style solder nozzle tips",
    },
    {
      xPos: "30%",
      yPos: 637,
      text: "Automatic nozzle conditioning system",
    },
    {
      xPos: 901,
      yPos: 512,
      text: "Thermal nozzle calibration",
    },
    {
      xPos: 958,
      yPos: 396,
      text: "Solder level detect and motorised wire feeder",
    },
    {
      xPos: 920,
      yPos: 250,
      text: "Wave height correction",
    },
    {
      xPos: 1075,
      yPos: 185,
      text: "DC drives on all axes",
    },
    {
      xPos: 964,
      yPos: 80,
      text: "Colour programming camera",
    },
    {
      xPos: 62,
      yPos: 15,
      text: "Automatic fiducial correction",
    },
    {
      xPos: 139,
      yPos: 142,
      text: "Drop Jet fluxer",
    },
    {
      xPos: 111,
      yPos: 270,
      text: "Internal fume extraction",
    },
    {
      xPos: 130,
      yPos: 404,
      text: "Light stack",
    },
    {
      xPos: 80,
      yPos: 587,
      text: "Integral PC and monitor",
    },
    {
      xPos: 80,
      yPos: 647,
      text: "New PillarCOMM control software",
    },
    {
      xPos: 901,
      yPos: 602,
      text: "New PillarCOMM control software",
    },
    {
      xPos: 988,
      yPos: 456,
      text: "Multiple level password protection",
    },
    {
      xPos: 990,
      yPos: 320,
      text: "IPC-2591 (CFX), Smart Factory ready",
    },
  ],
  "Fusion Layouts": [
    {
      xPos: 162,
      yPos: 85,
      text: "Integral PC and machine mounted Titanium monitor",
    },
    {
      xPos: 189,
      yPos: 212,
      text: "In-line motor driven width adjust through feed conveyor",
    },
    {
      xPos: 201,
      yPos: 330,
      text: "Conveyor side clamping",
    },
    {
      xPos: 160,
      yPos: 454,
      text: "DC servo drives with encoders on X, Y & Z axis",
    },
    {
      xPos: 305,
      yPos: 537,
      text: "Inerted Nitrogen system",
    },
    {
      xPos: "30%",
      yPos: 637,
      text: "Internal fume extraction",
    },
    {
      xPos: 901,
      yPos: 512,
      text: "Titanium Drop-Jet fluxer",
    },
    {
      xPos: 958,
      yPos: 396,
      text: "Motorised wire feed auto solder top-up & solder level detect",
    },
    {
      xPos: 920,
      yPos: 250,
      text: "Solder wave height measurement and correction system",
    },
    {
      xPos: 1075,
      yPos: 185,
      text: "Solder bath coding – identifies correct bath for program",
    },
    {
      xPos: 964,
      yPos: 80,
      text: "Thermal nozzle calibration system using integrated setting camera",
    },
    {
      xPos: 62,
      yPos: 15,
      text: "Auto-nozzle conditioning system",
    },
    {
      xPos: 139,
      yPos: 142,
      text: "Pump rpm",
    },
    {
      xPos: 111,
      yPos: 270,
      text: "Set of AP solder nozzle tips",
    },
    {
      xPos: 130,
      yPos: 404,
      text: "Colour programming camera",
    },
    {
      xPos: 80,
      yPos: 587,
      text: "Process viewing camera",
    },
    {
      xPos: 80,
      yPos: 647,
      text: "Automatic fiducial correction system",
    },
    {
      xPos: 901,
      yPos: 602,
      text: "Multiple level password protection",
    },
    {
      xPos: 988,
      yPos: 456,
      text: "Light stack",
    },
    {
      xPos: 990,
      yPos: 320,
      text: "Flux level sensor",
    },
    {
      xPos: 901,
      yPos: 602,
      text: "PillarCOMM Windows® based ‘Point & Click’ interface",
    },
    {
      xPos: 988,
      yPos: 456,
      text: "Lead-free compatible",
    },
    {
      xPos: 990,
      yPos: 320,
      text: "Day-to-day service kit",
    },
  ],
};

export const FEATURES_INFOS = {
  default: [
    "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
    "The fluxer/preheat combo operates in tandem with the selective soldering unit and can be configured with either Drop-Jet or ultrasonic flux heads and both top and bottom side IR preheat functions. Twin pitch fluxheads are available for further cycle time reductions.",
    "The machine utilizes an XY positioning system to position the flux head as required. A Drop-Jet flux head is supplied as standard with an ultrasonic head being ordered as an alternative or in addition to the standard head. Both systems provide precise control of the flux being deposited on the board.",
    "Programming and control of the machine is via a PC using the Windows® based new generation PillarCOMM software. Programs and machine configuration are protected by a customer selectable, multilevel password security system.",
    "The machine is freely programmable to cater to different board layouts. Each flux joint is separately programmable to its own set of required parameters. The programming system employs a component data library enabling quantities of similar joints to use the same fluxing data outline. Visually assisted self-teach alignment allows for fast and accurate board programming.",
  ],
  "Orissa Synchrodex": [
    "Designed as a modular system, the Synchrodex range of in-line equipment offers the ultimate in flexibility with the ability to upgrade to high speed throughput when multiple modules are placed together.",
    "Supplied as standard with our patented design Drop-Jet fluxer, this system offers accurate, controlled flux deposition either prior to or during the optional top side preheat function. Preheat can be controlled via an optional top-side closed loop pyrometer system for optimum temperature profile regulation.",
    "Our low maintenance solder bath and pump mechanism moves in three axes. Solder is applied using our proven technology single point AP nozzle design incorporating patented spiral solder return to bath technology offering increased wave stability with reduced potential for solder balls. The system can also accommodate our latest generation micro nozzle, together with Jet-Wave, Wide-Wave and dedicated multi-tube single dip solder technology.",
    "As with all Pillarhouse systems, the soldering process is enhanced by a hot Nitrogen curtain which provides an inert atmosphere for the soldering process and also assists in the prevention of oxidation. This process provides a local preheat to the joint thus reducing thermal shock to localised components.",
    "The Synchrodex is controlled by a PC, through PillarCOMM, a Windows® based ‘Point & Click’ interface with PCB image display. Additionally, our optional PillarPAD offline package allows the operator to produce programs independently from the machine using Gerber data.",
  ],
  "Orissa Synchrodex Pro": [
    "Designed as a modular system, the Synchrodex Pro range of in-line equipment offers the ultimate in flexibility with the ability to upgrade to high speed throughput when multiple modules are placed together.",
    "Supplied as standard with our patented design Drop-Jet fluxer, this system offers accurate, controlled flux deposition either prior to or during the optional top side preheat function. Preheat can be controlled via an optional top-side closed loop pyrometer system for optimum temperature profile regulation.",
    "Our low maintenance solder bath and pump mechanism moves in three axes. Solder is applied using our proven technology single point AP nozzle design incorporating patented spiral solder return to bath technology offering increased wave stability with reduced potential for solder balls. The system can also accommodate our latest generation micro nozzle, together with Jet-Wave, Wide-Wave and dedicated multi-tube single dip solder technology.",
    "As with all Pillarhouse systems, the soldering process is enhanced by a hot Nitrogen curtain which provides an inert atmosphere for the soldering process and also assists in the prevention of oxidation. This process provides a local preheat to the joint thus reducing thermal shock to localised components.",
    "The Synchrodex Pro is controlled by a PC, through new generation PillarCOMM, a Windows® based ‘Point & Click’ interface with PCB image display. Additionally, our optional PillarPAD offline package allows the operator to produce programs independently from the machine using Gerber data.",
  ],
  "Fusion Layouts": [
    "Incorporating high speed PCB transfer, the Orissa Fusion platform offers the ultimate in flexibility, coupled with reduced line length at a lower cost compared to current market offerings.",
    "The standard four station cell can be configured to handle PCB’s up to 381mm x 460mm - fluxer, preheat, solder, solder. For high speed applications this same unit can be configured as fluxer/preheat and up to three solder modules with as many as five heater options.",
  ],
};

export const SYSTEM_OPTIONS = {
  default: [
    "Top side IR preheat, with closed-loop control",
    "Motorised underside IR preheat, at all cell positions",
    "Ultrasonic fluxing",
    "Dual Drop-Jet / Ultrasonic fluxing",
  ],
  "Orissa Synchrodex": [
    "Ultrasonic fluxing",
    "Dual Drop-Jet / ultrasonic fluxing",
    "Top-side instant IR preheat",
    "Closed loop pyrometer temperature control",
    "Bottom-side hot Nitrogen selective preheat",
    "Laser PCB warp correction",
    "Solder reel identification",
    "Solder bath coding - identifies correct bath for program",
    "Encoders on X, Y and Z axis",
    "Nitrogen generator",
  ],
  "Orissa Synchrodex Pro": [
    "High definition process viewing camera",
    "Top side IR preheat, with closed-loop control",
    "Motorised underside IR preheat, at all cell positions",
    "Ultrasonic fluxing",
    "Dual Drop-Jet / Ultrasonic fluxing",
    "Laser-based PCB warp correction",
    "Solder reel identification",
    "Nitrogen generator",
  ],
  "Fusion Layouts": [
    "Ultrasonic fluxing",
    "Dual Drop-Jet / ultrasonic fluxing",
    "Top-side instant IR preheat",
    "Bottom-side IR preheat",
    "Closed loop pyrometer temperature control",
    "Large solder bath for dedicated single dip applications",
    "Laser PCB warp correction",
    "1.5mm micro nozzle",
    "Solder reel identification",
    "Auto-nozzle conditioning system",
    "Larger PCB handling size",
    "Nitrogen generator",
  ],
};

export const MONITORING_OPTIONS = {
  default: [
    "Flux spray monitoring",
    "Flux flow monitoring",
    "Flux spray & flow monitoring",
  ],
  "Orissa Synchrodex": [
    "Flux presence sensor - thermistor style",
    "Flux spray, flow and spray & flow",
    "O2 ppm",
    "Nitrogen flow",
  ],
  "Orissa Synchrodex Pro": [
    "Flux spray, flow and spray & flow",
    "Pump rpm",
    "O2 ppm",
    "Nitrogen flow",
  ],
  "Fusion Layouts": [
    "Flux presence sensor - thermistor style",
    "Flux spray, flow and spray & flow",
    "O2 ppm",
    "Nitrogen flow",
  ],
};

export const PRODUCT_SLIDES = {
  default: [
    {
      type: "image",
      description:
        "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
      content: `${IMAGE_BASE_URL}products/fusion-4.png`,
    },
    {
      type: "image",
      description:
        "The fluxer/preheat combo operates in tandem with the selective soldering unit and can be configured with either Drop-Jet or ultrasonic flux heads and both top and bottom side IR preheat functions. Twin pitch fluxheads are available for further cycle time reductions.",
      content: `${IMAGE_BASE_URL}products/jade-s-200-mk2.png`,
    },
    {
      type: "image",
      description:
        "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
      content: `${IMAGE_BASE_URL}products/fusion-4.png`,
    },
    {
      type: "image",
      description:
        "The fluxer/preheat combo operates in tandem with the selective soldering unit and can be configured with either Drop-Jet or ultrasonic flux heads and both top and bottom side IR preheat functions. Twin pitch fluxheads are available for further cycle time reductions.",
      content: `${IMAGE_BASE_URL}products/jade-s-200-mk2.png`,
    },
  ],
  fluxing: [
    {
      type: "video",
      name: "Optical Flux Presence Sensor",
      option: "optical_fluc_presence_sensor",
      description:
        "Ensure perfect flux application with the Optical Flux Presence Sensor. This advanced sensor detects the precise presence of flux, guaranteeing optimal soldering conditions. Enhance quality control and reduce errors with real-time monitoring.",
      content: `${VIDEO_BASE_URL}enhancements/Optical Flux Presence Sensor.mp4`,
    },
    {
      type: "image",
      name: "Flux Flow Monitoring",
      option: "flux_flow_monitoring",
      description:
        "Ensure optimal process control with Flux Flow Monitoring. It detects flow and lack of flow, estimates spray volume, and halts the process if spraying stops, guaranteeing high-level precision and reliability.",
      content: `${IMAGE_BASE_URL}enhancements/flux_flow_meter.jpg`,
    },
  ],
  soldering: [
    {
      type: "video",
      name: "Jetwave",
      option: "jetwave",
      description:
        "Ensure optimal process control with Flux Flow Monitoring. It detects flow and lack of flow, estimates spray volume, and halts the process if spraying stops, guaranteeing high-level precision and reliability.",
      content: `${VIDEO_BASE_URL}enhancements/Jet Wave.mp4`,
    },
    {
      type: "video",
      name: "Auto Nozzle Tinning",
      option: "auto_nozzle_tinning",
      description:
        "Enhance nozzle performance with Pillarhouse's Automatic Nozzle Tinning. This hands-free process applies just the right amount of cleaning medium, ensuring optimal nozzle wetting and reliability for peak functionality.",
      content: `${VIDEO_BASE_URL}enhancements/Auto Nozzle Conditioning.mp4`,
    },
    {
      type: "video",
      name: "Auto Level Detect and Top Up",
      option: "auto_level_detect",
      description:
        "Maintain perfect solder levels with Auto Level Detect and Top Up. This feature closely monitors solder levels to ensure constant head pressure and precise control of solder pumping height. Small incremental loading and level anticipation reduce cycle times for enhanced efficiency.",
      content: `${VIDEO_BASE_URL}enhancements/Feeder short.mp4`,
    },
  ],
  other: [
    {
      type: "image",
      name: "Fiducial Dynamic Correction",
      option: "fiducial_dynamic_correction",
      description:
        "Achieve unparalleled precision with Automatic Fiducial Correction. This advanced feature corrects XY and theta axes with high accuracy, using any unique and accurately positioned mark—no specific fiducial marks required.",
      content: `${IMAGE_BASE_URL}enhancements/Fiducial.JPG`,
    },
    {
      type: "video",
      name: "PCB Warpage Correction",
      option: "pcb_warpage_correction",
      description:
        "Ensure flawless soldering with PCB Active Warpage Correction!.Our system checks PCB height at programmed locations before soldering and adjusts Z height as needed, preventing skips and PCB contact.",
      content: `${VIDEO_BASE_URL}enhancements/boardwarp.mp4`,
    },
    {
      type: "image",
      name: "Electromagnetic Pump",
      option: "electromagnetic_pump",
      description:
        "Discover the superior efficiency of our Electromagnetic Pump, featuring Pillarhouse design for enhanced thermal continuity and reduced pumping variation.",
      content: `${IMAGE_BASE_URL}enhancements/Magnetic Pump.png`,
    },
    {
      type: "video",
      name: "Dual Solder Baths",
      option: "dual_solder_baths",
      description:
        "Maximize efficiency with our Dual Bath system. Whether dual bath on XY or dual XY configurations, customize to your requirements for high flexibility, high throughput, or both.",
      content: `${VIDEO_BASE_URL}enhancements/TwinBath.mp4`,
    },
    {
      type: null,
      name: "MES (Manufacturing Equipment Systems)",
      option: "manufacturing_equipment_systems",
      description:
        "Unlock the power of MES with Pillarhouse. From basic data transfer to advanced database parsing and full Factory 4.0 data tracking, our customizable solutions fit every level of complexity and budget. As pioneers in Factory 4.0 software development, we lead the way in modern manufacturing integration.",
      content: null,
    },
    {
      type: "image",
      name: "Automatic Rework Integration",
      option: "automatic_rework_integration",
      description:
        "Streamline corrections with Pillarhouse's Automatic Rework Integration! Our open architecture interfaces with any AOI brand, automatically programming rework points following wave or other processes. A manual version with a workstation is also available, both require the barcode program change option.",
      content: `${IMAGE_BASE_URL}enhancements/AOI-Automatic.png`,
    },
    {
      type: null,
      name: "Internal Monitoring of Machine Temperature",
      option: "internal_monitoring_temperature",
      description:
        "Optimize performance with Internal Monitoring of Machine Temperature. Unlike hot air systems, it controls ambient overload from constant high thermal demand by detecting temperature changes and compensating at the board level.",
      content: null,
    },
    {
      type: null,
      name: "Automatic Program ID and Change",
      option: "automatic_program_id",
      description:
        "Streamline your operations with our Barcode Reader integration. Whether handheld or mounted, it ensures correct program loading every time. Upgrade with full MES and FIS PCB tracking by serial number for enhanced efficiency and traceability.",
      content: null,
    },
  ],
};

export const OTHER_CHOICES = [
  {
    name: "Pilot",
    forwardTo: "/product?name=pilot",
    description:
      "Entry-level, benchtop, single point selective soldering system",
    content: `${IMAGE_BASE_URL}products/pilot.png`,
  },
  {
    name: "JADE MKII",
    forwardTo: "/product?name=Jade MKII",
    description:
      "Elementary, flexible, single point selective soldering system",
    content: `${IMAGE_BASE_URL}products/jade-mk2.png`,
  },
  {
    name: "QUADRON (PLC)",
    forwardTo: "/product?name=Quadron",
    description: "High specification, PLC, rotary soldering machine",
    content: `${IMAGE_BASE_URL}products/quadron-plc.png`,
  },
  {
    name: "JADE MKIV",
    forwardTo: "/product?name=Jade MKIV",
    description:
      "Entry-level, benchtop, single point selective soldering system",
    content: `${IMAGE_BASE_URL}products/jade-mk4.png`,
  },
  {
    name: "JADE PRO",
    forwardTo: "/product?name=Jade Pro",
    description:
      "Enhanced, entry-level, single point selective soldering system",
    content: `${IMAGE_BASE_URL}products/jade-pro.png`,
  },
  {
    name: "JADE PRODEX",
    forwardTo: "/product?name=JADE Prodex",
    description:
      "Ultra-flexible, offline, multi-platform, quick load twin PCB rotary table selective soldering system",
    content: `${IMAGE_BASE_URL}products/jade-prodex.png`,
  },
];
