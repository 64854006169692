const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;
const VIDEO_BASE_URL = process.env.REACT_APP_VIDEO_BASE_URL;

// Define constants for each image and video path
const Fusion4Product =
  `${IMAGE_BASE_URL}products/fusion-4.png` ||
  "assets/images/products/fusion-4.png";
const JadeS200MK2Product =
  `${IMAGE_BASE_URL}products/jade-s-200-mk2.png` ||
  "assets/images/products/jade-s-200-mk2.png";
const Pilot =
  `${IMAGE_BASE_URL}products/pilot.png` || "assets/images/products/pilot.png";
const JadeMK2 =
  `${IMAGE_BASE_URL}products/jade-mk2.png` ||
  "assets/images/products/jade-mk2.png";
const QuadronPLC =
  `${IMAGE_BASE_URL}products/quadron-plc.png` ||
  "assets/images/products/quadron-plc.png";
const JadeMK4 =
  `${IMAGE_BASE_URL}products/jade-mk4.png` ||
  "assets/images/products/jade-mk4.png";
const JadePro =
  `${IMAGE_BASE_URL}products/jade-pro.png` ||
  "assets/images/products/jade-pro.png";
const JadeProDex =
  `${IMAGE_BASE_URL}products/jade-prodex.png` ||
  "assets/images/products/jade-prodex.png";

// Technology
const SynchrodexPreHeatLamps =
  `${IMAGE_BASE_URL}technology/Synchrodex Pro Pre-Heat Lamps.jpg` ||
  "assets/images/technology/Synchrodex Pro Pre-Heat Lamps.jpg";
const RingHeater =
  `${IMAGE_BASE_URL}technology/Ring Heater.jpg` ||
  "assets/images/technology/Ring Heater.jpg";

// Videos
const FluxMonitor1 =
  `${VIDEO_BASE_URL}technology/Drop-Jet Fluxing.mp4` ||
  "assets/images/technology/Drop-Jet Fluxing.mp4";
const MicroNozzleVideo =
  `${VIDEO_BASE_URL}technology/Micro Nozzle.mp4` ||
  "assets/technology/Micro Nozzle.mp4";
const ThermalNozzleVideo =
  `${VIDEO_BASE_URL}technology/Thermal Nozzle Calibration.mp4` ||
  "assets/technology/Thermal Nozzle Calibration.mp4";
const NozzleVerificationVideo =
  `${VIDEO_BASE_URL}technology/Nozzle Verification.mp4` ||
  "assets/technology/Nozzle Verification.mp4";
const TwinBathVideo =
  `${VIDEO_BASE_URL}technology/Top and Bottom Side Pre-Heat.mp4` ||
  "assets/videos/technology/Top and Bottom Side Pre-Heat.mp4";

// Products in options

export const EXISTING_KEYS = {
  Synchrodex: "Synchrodex",
  "Orissa Synchrodex": "Orissa Synchrodex",
  "Orissa Synchrodex Pro": "Orissa Synchrodex Pro",
  "Fusion Layouts": "Fusion Layouts",
};

export const EXISTING_TECHNOLOGY_KEYS = {
  fluxing: "fluxing",
  preheating: "preheating",
  soldering: "soldering",
};

export const FEATURES = {
  default: [
    {
      xPos: 262,
      yPos: 85,
      text: "IPC-2591 (CFX), Smart Factory ready",
    },
    {
      xPos: 189,
      yPos: 212,
      text: "Colour programming camera",
    },
    {
      xPos: 261,
      yPos: 330,
      text: "Drop Jet fluxer",
    },
    {
      xPos: 160,
      yPos: 454,
      text: "Flux level sensor",
    },
    {
      xPos: 305,
      yPos: 537,
      text: "Multiple level password protection",
    },
    {
      xPos: "30%",
      yPos: 627,
      text: "508mm x 508mm (20' x 20') and 610mm x 610mm (24' x 24') board handling sizes",
    },
    {
      xPos: 901,
      yPos: 512,
      text: "SMEMA compatible",
    },
    {
      xPos: 958,
      yPos: 396,
      text: "New PillarCOMM control software",
    },
    {
      xPos: 920,
      yPos: 250,
      text: "Internal fume extraction",
    },
    {
      xPos: 1075,
      yPos: 165,
      text: "Day-to-day service kit",
    },
    {
      xPos: 964,
      yPos: 80,
      text: "DC drives on all axes",
    },
  ],
  "Orissa Synchrodex": [
    {
      xPos: 162,
      yPos: 85,
      text: "In-line motor driven auto width adjust through feed synchronous movement conveyor",
    },
    {
      xPos: 189,
      yPos: 212,
      text: "Conveyor side clamping",
    },
    {
      xPos: 201,
      yPos: 330,
      text: "Integral PC and machine mounted monitor",
    },
    {
      xPos: 160,
      yPos: 454,
      text: "Motorised auto-solder top-up (wire feed) & solder level detect",
    },
    {
      xPos: 305,
      yPos: 537,
      text: "Heated inerted Nitrogen system",
    },
    {
      xPos: "30%",
      yPos: 637,
      text: "Titanium Drop-Jet fluxer",
    },
    {
      xPos: 901,
      yPos: 512,
      text: "Set of AP solder nozzle tips",
    },
    {
      xPos: 958,
      yPos: 396,
      text: "Internal fume extraction",
    },
    {
      xPos: 920,
      yPos: 250,
      text: "Colour programming camera",
    },
    {
      xPos: 1075,
      yPos: 185,
      text: "Solder wave height measurement and correction",
    },
    {
      xPos: 964,
      yPos: 80,
      text: "Pump rpm",
    },
    {
      xPos: 62,
      yPos: 15,
      text: "Auto-nozzle conditioning system",
    },
    {
      xPos: 139,
      yPos: 142,
      text: "Thermal nozzle calibration system using integrated setting camera",
    },
    {
      xPos: 111,
      yPos: 270,
      text: "Windows® based PillarCOMM ‘Point & Click’ interface",
    },
    {
      xPos: 130,
      yPos: 404,
      text: "Light stack",
    },
    {
      xPos: 80,
      yPos: 587,
      text: "Auto fiducial recognition and correction system",
    },
    {
      xPos: 80,
      yPos: 647,
      text: "Flux level sensor",
    },
    {
      xPos: 901,
      yPos: 602,
      text: "Multiple level password protection",
    },
    {
      xPos: 988,
      yPos: 456,
      text: "PillarPAD offline programming system",
    },
    {
      xPos: 990,
      yPos: 320,
      text: "SMEMA compatible",
    },
    {
      xPos: 775,
      yPos: 135,
      text: "Process viewing camera with record feature",
    },
    {
      xPos: 1164,
      yPos: 80,
      text: "Lead-free capability",
    },
    {
      xPos: 964,
      yPos: 15,
      text: "Day-to-day service kit",
    },
  ],
  "Orissa Synchrodex Pro": [
    {
      xPos: 162,
      yPos: 85,
      text: "508mm x 508mm (20' x 20') and 610mm x 610mm (24' x 24') board handling sizes",
    },
    {
      xPos: 189,
      yPos: 212,
      text: "Interchangeable Mechanical or Magnetic solder bath and pump",
    },
    {
      xPos: 201,
      yPos: 330,
      text: "Lead-free solder capability",
    },
    {
      xPos: 160,
      yPos: 454,
      text: "Inerted Nitrogen system",
    },
    {
      xPos: 305,
      yPos: 537,
      text: "Set of AP style solder nozzle tips",
    },
    {
      xPos: "30%",
      yPos: 637,
      text: "Automatic nozzle conditioning system",
    },
    {
      xPos: 901,
      yPos: 512,
      text: "Thermal nozzle calibration",
    },
    {
      xPos: 958,
      yPos: 396,
      text: "Solder level detect and motorised wire feeder",
    },
    {
      xPos: 920,
      yPos: 250,
      text: "Wave height correction",
    },
    {
      xPos: 1075,
      yPos: 185,
      text: "DC drives on all axes",
    },
    {
      xPos: 964,
      yPos: 80,
      text: "Colour programming camera",
    },
    {
      xPos: 62,
      yPos: 15,
      text: "Automatic fiducial correction",
    },
    {
      xPos: 139,
      yPos: 142,
      text: "Drop Jet fluxer",
    },
    {
      xPos: 111,
      yPos: 270,
      text: "Internal fume extraction",
    },
    {
      xPos: 130,
      yPos: 404,
      text: "Light stack",
    },
    {
      xPos: 80,
      yPos: 587,
      text: "Integral PC and monitor",
    },
    {
      xPos: 80,
      yPos: 647,
      text: "New PillarCOMM control software",
    },
    {
      xPos: 901,
      yPos: 602,
      text: "New PillarCOMM control software",
    },
    {
      xPos: 988,
      yPos: 456,
      text: "Multiple level password protection",
    },
    {
      xPos: 990,
      yPos: 320,
      text: "IPC-2591 (CFX), Smart Factory ready",
    },
  ],
  "Fusion Layouts": [
    {
      xPos: 162,
      yPos: 85,
      text: "Integral PC and machine mounted Titanium monitor",
    },
    {
      xPos: 189,
      yPos: 212,
      text: "In-line motor driven width adjust through feed conveyor",
    },
    {
      xPos: 201,
      yPos: 330,
      text: "Conveyor side clamping",
    },
    {
      xPos: 160,
      yPos: 454,
      text: "DC servo drives with encoders on X, Y & Z axis",
    },
    {
      xPos: 305,
      yPos: 537,
      text: "Inerted Nitrogen system",
    },
    {
      xPos: "30%",
      yPos: 637,
      text: "Internal fume extraction",
    },
    {
      xPos: 901,
      yPos: 512,
      text: "Titanium Drop-Jet fluxer",
    },
    {
      xPos: 958,
      yPos: 396,
      text: "Motorised wire feed auto solder top-up & solder level detect",
    },
    {
      xPos: 920,
      yPos: 250,
      text: "Solder wave height measurement and correction system",
    },
    {
      xPos: 1075,
      yPos: 185,
      text: "Solder bath coding – identifies correct bath for program",
    },
    {
      xPos: 964,
      yPos: 80,
      text: "Thermal nozzle calibration system using integrated setting camera",
    },
    {
      xPos: 62,
      yPos: 15,
      text: "Auto-nozzle conditioning system",
    },
    {
      xPos: 139,
      yPos: 142,
      text: "Pump rpm",
    },
    {
      xPos: 111,
      yPos: 270,
      text: "Set of AP solder nozzle tips",
    },
    {
      xPos: 130,
      yPos: 404,
      text: "Colour programming camera",
    },
    {
      xPos: 80,
      yPos: 587,
      text: "Process viewing camera",
    },
    {
      xPos: 80,
      yPos: 647,
      text: "Automatic fiducial correction system",
    },
    {
      xPos: 901,
      yPos: 602,
      text: "Multiple level password protection",
    },
    {
      xPos: 988,
      yPos: 456,
      text: "Light stack",
    },
    {
      xPos: 990,
      yPos: 320,
      text: "Flux level sensor",
    },
    {
      xPos: 901,
      yPos: 602,
      text: "PillarCOMM Windows® based ‘Point & Click’ interface",
    },
    {
      xPos: 988,
      yPos: 456,
      text: "Lead-free compatible",
    },
    {
      xPos: 990,
      yPos: 320,
      text: "Day-to-day service kit",
    },
  ],
};

export const FEATURES_INFOS = {
  default: [
    "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
    "The fluxer/preheat combo operates in tandem with the selective soldering unit and can be configured with either Drop-Jet or ultrasonic flux heads and both top and bottom side IR preheat functions. Twin pitch fluxheads are available for further cycle time reductions.",
    "The machine utilizes an XY positioning system to position the flux head as required. A Drop-Jet flux head is supplied as standard with an ultrasonic head being ordered as an alternative or in addition to the standard head. Both systems provide precise control of the flux being deposited on the board.",
    "Programming and control of the machine is via a PC using the Windows® based new generation PillarCOMM software. Programs and machine configuration are protected by a customer selectable, multilevel password security system.",
    "The machine is freely programmable to cater to different board layouts. Each flux joint is separately programmable to its own set of required parameters. The programming system employs a component data library enabling quantities of similar joints to use the same fluxing data outline. Visually assisted self-teach alignment allows for fast and accurate board programming.",
  ],
  "Orissa Synchrodex": [
    "Designed as a modular system, the Synchrodex range of in-line equipment offers the ultimate in flexibility with the ability to upgrade to high speed throughput when multiple modules are placed together.",
    "Supplied as standard with our patented design Drop-Jet fluxer, this system offers accurate, controlled flux deposition either prior to or during the optional top side preheat function. Preheat can be controlled via an optional top-side closed loop pyrometer system for optimum temperature profile regulation.",
    "Our low maintenance solder bath and pump mechanism moves in three axes. Solder is applied using our proven technology single point AP nozzle design incorporating patented spiral solder return to bath technology offering increased wave stability with reduced potential for solder balls. The system can also accommodate our latest generation micro nozzle, together with Jet-Wave, Wide-Wave and dedicated multi-tube single dip solder technology.",
    "As with all Pillarhouse systems, the soldering process is enhanced by a hot Nitrogen curtain which provides an inert atmosphere for the soldering process and also assists in the prevention of oxidation. This process provides a local preheat to the joint thus reducing thermal shock to localised components.",
    "The Synchrodex is controlled by a PC, through PillarCOMM, a Windows® based ‘Point & Click’ interface with PCB image display. Additionally, our optional PillarPAD offline package allows the operator to produce programs independently from the machine using Gerber data.",
  ],
  "Orissa Synchrodex Pro": [
    "Designed as a modular system, the Synchrodex Pro range of in-line equipment offers the ultimate in flexibility with the ability to upgrade to high speed throughput when multiple modules are placed together.",
    "Supplied as standard with our patented design Drop-Jet fluxer, this system offers accurate, controlled flux deposition either prior to or during the optional top side preheat function. Preheat can be controlled via an optional top-side closed loop pyrometer system for optimum temperature profile regulation.",
    "Our low maintenance solder bath and pump mechanism moves in three axes. Solder is applied using our proven technology single point AP nozzle design incorporating patented spiral solder return to bath technology offering increased wave stability with reduced potential for solder balls. The system can also accommodate our latest generation micro nozzle, together with Jet-Wave, Wide-Wave and dedicated multi-tube single dip solder technology.",
    "As with all Pillarhouse systems, the soldering process is enhanced by a hot Nitrogen curtain which provides an inert atmosphere for the soldering process and also assists in the prevention of oxidation. This process provides a local preheat to the joint thus reducing thermal shock to localised components.",
    "The Synchrodex Pro is controlled by a PC, through new generation PillarCOMM, a Windows® based ‘Point & Click’ interface with PCB image display. Additionally, our optional PillarPAD offline package allows the operator to produce programs independently from the machine using Gerber data.",
  ],
  "Fusion Layouts": [
    "Incorporating high speed PCB transfer, the Orissa Fusion platform offers the ultimate in flexibility, coupled with reduced line length at a lower cost compared to current market offerings.",
    "The standard four station cell can be configured to handle PCB’s up to 381mm x 460mm - fluxer, preheat, solder, solder. For high speed applications this same unit can be configured as fluxer/preheat and up to three solder modules with as many as five heater options.",
  ],
};

export const SYSTEM_OPTIONS = {
  default: [
    "Top side IR preheat, with closed-loop control",
    "Motorised underside IR preheat, at all cell positions",
    "Ultrasonic fluxing",
    "Dual Drop-Jet / Ultrasonic fluxing",
  ],
  "Orissa Synchrodex": [
    "Ultrasonic fluxing",
    "Dual Drop-Jet / ultrasonic fluxing",
    "Top-side instant IR preheat",
    "Closed loop pyrometer temperature control",
    "Bottom-side hot Nitrogen selective preheat",
    "Laser PCB warp correction",
    "Solder reel identification",
    "Solder bath coding - identifies correct bath for program",
    "Encoders on X, Y and Z axis",
    "Nitrogen generator",
  ],
  "Orissa Synchrodex Pro": [
    "High definition process viewing camera",
    "Top side IR preheat, with closed-loop control",
    "Motorised underside IR preheat, at all cell positions",
    "Ultrasonic fluxing",
    "Dual Drop-Jet / Ultrasonic fluxing",
    "Laser-based PCB warp correction",
    "Solder reel identification",
    "Nitrogen generator",
  ],
  "Fusion Layouts": [
    "Ultrasonic fluxing",
    "Dual Drop-Jet / ultrasonic fluxing",
    "Top-side instant IR preheat",
    "Bottom-side IR preheat",
    "Closed loop pyrometer temperature control",
    "Large solder bath for dedicated single dip applications",
    "Laser PCB warp correction",
    "1.5mm micro nozzle",
    "Solder reel identification",
    "Auto-nozzle conditioning system",
    "Larger PCB handling size",
    "Nitrogen generator",
  ],
};

export const MONITORING_OPTIONS = {
  default: [
    "Flux spray monitoring",
    "Flux flow monitoring",
    "Flux spray & flow monitoring",
  ],
  "Orissa Synchrodex": [
    "Flux presence sensor - thermistor style",
    "Flux spray, flow and spray & flow",
    "O2 ppm",
    "Nitrogen flow",
  ],
  "Orissa Synchrodex Pro": [
    "Flux spray, flow and spray & flow",
    "Pump rpm",
    "O2 ppm",
    "Nitrogen flow",
  ],
  "Fusion Layouts": [
    "Flux presence sensor - thermistor style",
    "Flux spray, flow and spray & flow",
    "O2 ppm",
    "Nitrogen flow",
  ],
};

export const TECHNOLOGY_SLIDES = {
  default: [
    {
      type: "image",
      description:
        "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
      content: Fusion4Product,
    },
    {
      type: "image",
      description:
        "The fluxer/preheat combo operates in tandem with the selective soldering unit and can be configured with either Drop-Jet or ultrasonic flux heads and both top and bottom side IR preheat functions. Twin pitch fluxheads are available for further cycle time reductions.",
      content: JadeS200MK2Product,
    },
    {
      type: "image",
      description:
        "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
      content: Fusion4Product,
    },
    {
      type: "image",
      description:
        "The fluxer/preheat combo operates in tandem with the selective soldering unit and can be configured with either Drop-Jet or ultrasonic flux heads and both top and bottom side IR preheat functions. Twin pitch fluxheads are available for further cycle time reductions.",
      content: JadeS200MK2Product,
    },
  ],
  fluxing: [
    {
      type: "video",
      name: "Drop Jet/Ultrasonic Fluxing",
      option: "drop_jet_fluxing",
      description:
        "Maximize productivity with the Additional Flux head, designed to handle two different flux types seamlessly. Switch between applications with ease, ensuring versatility and efficiency in your soldering processes. Engineered for flexibility and reliability, the Additional Flux Head is the perfect addition to your production line.",
      content: FluxMonitor1,
    },
  ],
  preheating: [
    {
      type: "video",
      name: "Top Side IR Pre-Heat",
      option: "top_side_ir",
      description:
        "Experience essential topside IR preheating for consistent and repeatable selective soldering results. Pillarhouse sets itself apart with fast-acting medium-wavelength double-end-wound lamps featuring individual control or full closed-loop functionality, ensuring unmatched speed and high thermal mass capability in your manufacturing process.",
      content: TwinBathVideo,
    },
    {
      type: null,
      name: "Closed-loop Control",
      option: "closed_loop_control",
      description:
        "Ensure precision and consistency with our Closed-Loop Top Side Pyrometer Control. This system provides real-time temperature adjustments, maintaining ideal conditions for soldering. Achieve superior quality and reliability in your electronic assemblies with our cutting-edge pyrometer control technology.",
      content: null,
    },
    {
      type: "image",
      name: "Bottom Side IR Pre-Heat",
      option: "bottom_side_ir",
      description:
        "Achieve flawless soldering with our Bottom Side Pre-Heat technology. Designed to evenly heat the underside of your PCB, it minimizes warping and thermal shock, resulting in stronger, more reliable joints. Enhance your manufacturing process with this essential tool for quality and consistency.",
      content: SynchrodexPreHeatLamps,
    },
    {
      type: null,
      name: null,
      option: "bottom_side_pyro_control",
      description:
        "Experience unparalleled temperature accuracy with our Closed-Loop Bottom Side Pyrometer Control. This advanced system continuously monitors and adjusts heat levels, ensuring optimal thermal conditions for soldering. Eliminate guesswork and achieve perfect results every time with our state-of-the-art pyrometer technology.",
      content: null,
    },
    {
      type: "image",
      name: "Ring Heater",
      option: "ring_heater",
      description:
        "Upgrade your soldering setup with our versatile Ring Heater.  Designed for even heat distribution around the soldering area, it ensures uniform temperature control, reducing thermal gradients. Perfect for precision soldering tasks, this heater enhances reliability and quality in electronic manufacturing.",
      content: RingHeater,
    },
  ],
  soldering: [
    {
      type: "video",
      name: "1.2mm Micro Nozzle",
      option: "1.2mm_micro_nozzle",
      description:
        "Introducing the 1.2 Micro Thermal Nozzle: a multi-patented marvel with the world's smallest keepout zones. Experience stable flow without freezing, setting new standards in precision and efficiency.",
      content: MicroNozzleVideo,
    },
    {
      type: "video",
      name: "Thermal Calibration",
      option: "thermal_calibration",
      description:
        "Achieve unmatched accuracy with Pillarhouse's Thermal Nozzle Calibration. Our integrated system measures the true contact patch of solder, ensuring unparalleled location precision. Unlike competitors, we rely on actual solder flow rather than known mechanical positions, setting us apart in delivering precise results.",
      content: ThermalNozzleVideo,
    },
    {
      type: "video",
      name: "Nozzle Verification",
      option: "nozzle_verfication",
      description:
        "Ensure precision with our Nozzle Verification system. Using a camera-based system, it verifies the installed nozzle in each bath, preventing incorrect soldering and potential PCB damage. Programs cannot run without the correct nozzle, which is locked in with a picture to assist operators.",
      content: NozzleVerificationVideo,
    },
  ],
};

export const OTHER_CHOICES = [
  {
    name: "Pilot",
    forwardTo: "/product?name=pilot",
    description:
      "Entry-level, benchtop, single point selective soldering system",
    content: Pilot,
  },
  {
    name: "JADE MKII",
    forwardTo: "/product?name=Jade MKII",
    description:
      "Elementary, flexible, single point selective soldering system",
    content: JadeMK2,
  },
  {
    name: "QUADRON (PLC)",
    forwardTo: "/product?name=Quadron",
    description: "High specification, PLC, rotary soldering machine",
    content: QuadronPLC,
  },
  {
    name: "JADE MKIV",
    forwardTo: "/product?name=Jade MKIV",
    description:
      "Entry-level, benchtop, single point selective soldering system",
    content: JadeMK4,
  },
  {
    name: "JADE PRO",
    forwardTo: "/product?name=Jade Pro",
    description:
      "Enhanced, entry-level, single point selective soldering system",
    content: JadePro,
  },
  {
    name: "JADE PRODEX",
    forwardTo: "/product?name=JADE Prodex",
    description:
      "Ultra-flexible, offline, multi-platform, quick load twin PCB rotary table selective soldering system",
    content: JadeProDex,
  },
];
