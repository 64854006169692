import { useLocation } from "react-router-dom";
import { useTranslation } from "hooks/useTranslation";
import { CharacteristicsSlider } from "./components/CharacteristicsSlider";
import { InformativeBlock } from "shared/components/InformativeBlock";
import {
  CHOOSE_US_ITEMS,
  EXISTING_MARKET_SECTOR_KEYS,
  GENERAL_DESC_BY_KEY,
  INFOS,
  MONITORING_OPTIONS,
  OTHER_MARKETS,
  RELATED_PRODUCTS,
  SYSTEM_OPTIONS,
} from "./constants";
import { SectionTitleLeftWaves } from "shared/components/SectionTitleLeftWaves";
import { AdvantageCard } from "shared/components/AdvantageCard";
import { OtherServiceItem } from "shared/components/OtherServiceItem";
import { ProductItem } from "shared/components/ProductItem";
import styles from "./MarketSector.module.scss";
import {
  IconSystemOptions,
  IconCurrentLocationGray,
  IconMonitoringOptions,
} from "icons";

export const MarketSector = () => {
  const { translate } = useTranslation();

  const location = useLocation();
  const { search } = location;
  const queryParams = new URLSearchParams(search);
  const name = queryParams.get("name") && queryParams.get("name").toLowerCase();

  const contentKey = EXISTING_MARKET_SECTOR_KEYS[name] || "default";

  return (
    <div className={styles.container}>
      <div className={styles.characteristicsSec}>
        <p className={styles.generalDesc}>
          {translate(GENERAL_DESC_BY_KEY[contentKey])}
        </p>
        <CharacteristicsSlider contentKey={contentKey} />
        <div className={styles.infos}>
          {INFOS[contentKey].map((info, idx) => (
            <InformativeBlock key={idx} text={info} />
          ))}
        </div>
        <div className={styles.systemOptions}>
          <SectionTitleLeftWaves title={translate("Advantages")} />
          <div className={styles.sysOptsContent}>
            <div className={styles.sysOptsColumn}>
              <div className={styles.colTitleBlock}>
                <IconSystemOptions />
                <p className={styles.colTitle}>{translate("System Options")}</p>
              </div>
              {SYSTEM_OPTIONS[contentKey].map((info, idx) => (
                <div key={idx} className={styles.sysOptionRow}>
                  <IconCurrentLocationGray />
                  <p className={styles.sysOptTxt}>{info}</p>
                </div>
              ))}
            </div>
            <div className={styles.sysOptsColumn}>
              <div className={styles.colTitleBlock}>
                <IconMonitoringOptions />
                <p className={styles.colTitle}>
                  {translate("Monitoring Options")}
                </p>
              </div>
              {MONITORING_OPTIONS[contentKey].map((info, idx) => (
                <div key={idx} className={styles.sysOptionRow}>
                  <IconCurrentLocationGray />
                  <p className={styles.sysOptTxt}>{info}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.whyChooseUs}>
          <SectionTitleLeftWaves title={translate("Why Choose Us")} />
          <div className={styles.content}>
            {CHOOSE_US_ITEMS[contentKey].map((item, idx) => (
              <AdvantageCard
                key={idx}
                icon={item.icon}
                title={translate(item.title)}
                text={translate(item.text)}
              />
            ))}
          </div>
        </div>
        <div className={styles.itemsSection}>
          <SectionTitleLeftWaves title={translate("Other Markets Served")} />
          <div className={styles.otherMarketsContent}>
            {OTHER_MARKETS.map((item, idx) => (
              <OtherServiceItem key={idx} {...item} />
            ))}
          </div>
        </div>
        <div className={styles.itemsSection}>
          <SectionTitleLeftWaves title={translate("Related Products")} />
          <div className={styles.otherMarketsContent}>
            {RELATED_PRODUCTS.map((item, idx) => (
              <ProductItem key={idx} {...item} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
