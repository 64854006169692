const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;
const VIDEO_BASE_URL = process.env.REACT_APP_VIDEO_BASE_URL;

// Define constants for each image and video path
const Fusion4Product =
  `${IMAGE_BASE_URL}products/fusion-4.png` ||
  "assets/images/products/fusion-4.png";
const JadeS200MK2Product =
  `${IMAGE_BASE_URL}products/jade-s-200-mk2.png` ||
  "assets/images/products/jade-s-200-mk2.png";
const Pilot =
  `${IMAGE_BASE_URL}products/pilot.png` || "assets/images/products/pilot.png";
const JadeMK2 =
  `${IMAGE_BASE_URL}products/jade-mk2.png` ||
  "assets/images/products/jade-mk2.png";
const QuadronPLC =
  `${IMAGE_BASE_URL}products/quadron-plc.png` ||
  "assets/images/products/quadron-plc.png";
const JadeMK4 =
  `${IMAGE_BASE_URL}products/jade-mk4.png` ||
  "assets/images/products/jade-mk4.png";
const JadePro =
  `${IMAGE_BASE_URL}products/jade-pro.png` ||
  "assets/images/products/jade-pro.png";
const JadeProDex =
  `${IMAGE_BASE_URL}products/jade-prodex.png` ||
  "assets/images/products/jade-prodex.png";

// Enhancements
const FluxMonitor1 =
  `${IMAGE_BASE_URL}enhancements/fluxmonitor1.jpeg` ||
  "assets/images/enhancements/fluxmonitor1.jpeg";
const FluxMonitor2 =
  `${IMAGE_BASE_URL}enhancements/fluxmonitor2.jpeg` ||
  "assets/images/enhancements/fluxmonitor2.jpeg";
const FluxFlowMeter =
  `${IMAGE_BASE_URL}enhancements/flux_flow_meter.jpg` ||
  "assets/images/enhancements/flux_flow_meter.jpg";
const Fiducial =
  `${IMAGE_BASE_URL}enhancements/fiducial.jpeg` ||
  "assets/images/enhancements/fiducial.jpeg";
const BarcodesAndTraceAbility =
  `${IMAGE_BASE_URL}enhancements/barcodes-traceability.png` ||
  "assets/images/enhancements/barcodes-traceability.png";
const ReelDetectImg =
  `${IMAGE_BASE_URL}enhancements/reeldetect.jpg` ||
  "assets/images/enhancements/reeldetect.jpg";
const RPMSensorImg =
  `${IMAGE_BASE_URL}enhancements/RPMSensor.png` ||
  "assets/images/enhancements/RPMSensor.png";

// Videos
const BoardWarpVideo =
  `${VIDEO_BASE_URL}enhancements/boardwarp.mp4` ||
  "assets/videos/enhancements/boardwarp.mp4";
const TwinBathVideo =
  `${VIDEO_BASE_URL}enhancements/TwinBath.mp4` ||
  "assets/videos/enhancements/TwinBath.mp4";
const WaveHeightVideo =
  `${VIDEO_BASE_URL}enhancements/waveHeight.mp4` ||
  "assets/videos/enhancements/waveHeight.mp4";
const SolderFeederVideo =
  `${VIDEO_BASE_URL}enhancements/solderFeeder.mp4` ||
  "assets/videos/enhancements/solderFeeder.mp4";
const ThermalNozzleCalVideo =
  `${VIDEO_BASE_URL}enhancements/thermalNozzleCal.mp4` ||
  "assets/videos/enhancements/thermalNozzleCal.mp4";
const BathTrolley =
  `${VIDEO_BASE_URL}enhancements/bathTrolley.mp4` ||
  "assets/videos/enhancements/bathTrolley.mp4";
const NozzleConditioningVideo =
  `${VIDEO_BASE_URL}enhancements/nozzleConditioning.mp4` ||
  "assets/videos/enhancements/nozzleConditioning.mp4";
const O2analyserVideo =
  `${VIDEO_BASE_URL}enhancements/O2analyser.mp4` ||
  "assets/videos/enhancements/O2analyser.mp4";

// Products in options
const Product1InOptions =
  `${IMAGE_BASE_URL}products/product-1-in-options.png` ||
  "assets/images/products/product-1-in-options.png";

// Orissa Fusion 4
const F4ReplaceBadColoringImg =
  `${IMAGE_BASE_URL}products/orissa-fusion-4/F4-replace-bad-coloring-const-angle.jpg` ||
  "assets/images/products/orissa-fusion-4/F4-replace-bad-coloring-const-angle.jpg";
const F4ReplaceWithChinaVideo =
  `${VIDEO_BASE_URL}products/orissa-fusion-4/F4-replace-with-china.mp4` ||
  "assets/videos/products/orissa-fusion-4/F4-replace-with-china.mp4";

// SP1600DBATH
const SP1600DBATHImg =
  `${IMAGE_BASE_URL}products/sp1600dbath/SP1600 DBATH.jpg` ||
  "assets/images/products/sp1600dbath/SP1600 DBATH.jpg";
const SP1600DBATHVideo1 =
  `${VIDEO_BASE_URL}products/sp1600dbath/SP VIDEO.mp4` ||
  "assets/videos/products/sp1600dbath/SP VIDEO.mp4";
const SP1600DBATHVideo2 =
  `${VIDEO_BASE_URL}products/sp1600dbath/SP ANIMATION.mp4` ||
  "assets/videos/products/sp1600dbath/SP ANIMATION.mp4";

export const EXISTING_KEYS = {
  Synchrodex: "Synchrodex",
  "Orissa Synchrodex": "Orissa Synchrodex",
  "Orissa Synchrodex Pro": "Orissa Synchrodex Pro",
  "Fusion Layouts": "Fusion Layouts",
  "Synchrodex Pro 1600 Dual Bath Solder Module":
    "Synchrodex Pro 1600 Dual Bath Solder Module",
  "Orissa Fusion 4": "Orissa Fusion 4",
};

export const EXISTING_ENHANCEMENTS_KEYS = {
  fluxing: "fluxing",
  soldering: "soldering",
  other: "other",
};

export const PRODUCTS_WITH_SLIDER_KEYS = {
  "Synchrodex Pro 1600 Dual Bath Solder Module":
    "Synchrodex Pro 1600 Dual Bath Solder Module",
  "Orissa Fusion 4": "Orissa Fusion 4",
};

export const FEATURES_INFOS = {
  default: [
    "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
    "The fluxer/preheat combo operates in tandem with the selective soldering unit and can be configured with either Drop-Jet or ultrasonic flux heads and both top and bottom side IR preheat functions. Twin pitch fluxheads are available for further cycle time reductions.",
    "The machine utilizes an XY positioning system to position the flux head as required. A Drop-Jet flux head is supplied as standard with an ultrasonic head being ordered as an alternative or in addition to the standard head. Both systems provide precise control of the flux being deposited on the board.",
    "Programming and control of the machine is via a PC using the Windows® based new generation PillarCOMM software. Programs and machine configuration are protected by a customer selectable, multilevel password security system.",
    "The machine is freely programmable to cater to different board layouts. Each flux joint is separately programmable to its own set of required parameters. The programming system employs a component data library enabling quantities of similar joints to use the same fluxing data outline. Visually assisted self-teach alignment allows for fast and accurate board programming.",
  ],
  "Orissa Synchrodex": [
    "Designed as a modular system, the Synchrodex range of in-line equipment offers the ultimate in flexibility with the ability to upgrade to high speed throughput when multiple modules are placed together.",
    "Supplied as standard with our patented design Drop-Jet fluxer, this system offers accurate, controlled flux deposition either prior to or during the optional top side preheat function. Preheat can be controlled via an optional top-side closed loop pyrometer system for optimum temperature profile regulation.",
    "Our low maintenance solder bath and pump mechanism moves in three axes. Solder is applied using our proven technology single point AP nozzle design incorporating patented spiral solder return to bath technology offering increased wave stability with reduced potential for solder balls. The system can also accommodate our latest generation micro nozzle, together with Jet-Wave, Wide-Wave and dedicated multi-tube single dip solder technology.",
    "As with all Pillarhouse systems, the soldering process is enhanced by a hot Nitrogen curtain which provides an inert atmosphere for the soldering process and also assists in the prevention of oxidation. This process provides a local preheat to the joint thus reducing thermal shock to localised components.",
    "The Synchrodex is controlled by a PC, through PillarCOMM, a Windows® based ‘Point & Click’ interface with PCB image display. Additionally, our optional PillarPAD offline package allows the operator to produce programs independently from the machine using Gerber data.",
  ],
  "Orissa Synchrodex Pro": [
    "Designed as a modular system, the Synchrodex Pro range of in-line equipment offers the ultimate in flexibility with the ability to upgrade to high speed throughput when multiple modules are placed together.",
    "Supplied as standard with our patented design Drop-Jet fluxer, this system offers accurate, controlled flux deposition either prior to or during the optional top side preheat function. Preheat can be controlled via an optional top-side closed loop pyrometer system for optimum temperature profile regulation.",
    "Our low maintenance solder bath and pump mechanism moves in three axes. Solder is applied using our proven technology single point AP nozzle design incorporating patented spiral solder return to bath technology offering increased wave stability with reduced potential for solder balls. The system can also accommodate our latest generation micro nozzle, together with Jet-Wave, Wide-Wave and dedicated multi-tube single dip solder technology.",
    "As with all Pillarhouse systems, the soldering process is enhanced by a hot Nitrogen curtain which provides an inert atmosphere for the soldering process and also assists in the prevention of oxidation. This process provides a local preheat to the joint thus reducing thermal shock to localised components.",
    "The Synchrodex Pro is controlled by a PC, through new generation PillarCOMM, a Windows® based ‘Point & Click’ interface with PCB image display. Additionally, our optional PillarPAD offline package allows the operator to produce programs independently from the machine using Gerber data.",
  ],
  "Fusion Layouts": [
    "Incorporating high speed PCB transfer, the Orissa Fusion platform offers the ultimate in flexibility, coupled with reduced line length at a lower cost compared to current market offerings.",
    "The standard four station cell can be configured to handle PCB’s up to 381mm x 460mm - fluxer, preheat, solder, solder. For high speed applications this same unit can be configured as fluxer/preheat and up to three solder modules with as many as five heater options.",
  ],
  "Synchrodex Pro 1600 Dual Bath Solder Module": [
    "The Synchrodex Pro is a modular, in-line selective soldering system designed for ultimate flexibility and high-speed throughput when multiple modules are combined.",
    "The 1600mm solder module supports both single and dual-bath operations, allowing alternate nozzle processes for 610mm x 610mm PCBs or simultaneous dual-board processing for 420mm x 610mm PCBs.",
    "Flexibility is enhanced by a software-controlled three-pin stop system for managing single or dual PCBs.",
    "With the XL frame, it can handle PCBs up to 1200mm x 610mm.",
    "Configurable with any Pillarhouse solder technology, including the 1.5mm Micro Nozzle, Jet Wave, and Multi-Dip, it offers rapid non-contact solder pot changeovers with an optional heated pot trolley.",
    "The Synchrodex Pro is controlled by a PC, through new generation PillarCOMM, a Windows® based ‘Point & Click’ interface with PCB image display. Additionally, our optional PillarPAD offline package allows the operator to produce programs independently from the machine using Gerber data.",
    "Controlled by PillarCOMM.NET software, it features a user-friendly ‘Point & Click’ interface and an offline mode for programming using Gerber data.",
  ],
  "Orissa Fusion 4": [
    "The Orissa Fusion 4 platform delivers unmatched flexibility with high-speed PCB transfer and shorter line lengths at a lower cost than competitors.",
    "The four-station Fusion 4 handles PCBs up to 381mm x 430mm (15” x 17”), or up to 381mm x 460mm (15” x 18”) without auto fiducial and warp correction.",
    "For high-speed operations, it can be configured as a fluxer/pre-heater with up to three solder modules and five heater options.",
    "Each solder cell supports any Pillarhouse soldering technology, from Custom Dip and Multi Dip to the patented 1.5mm Micro Nozzle.",
    "Controlled via PillarCOMM.NET software, a Windows-based interface with PCB image display, it also includes an offline mode for independent programming using Gerber data.",
  ],
};

export const SYSTEM_OPTIONS = {
  default: [
    "Top side IR preheat, with closed-loop control",
    "Motorised underside IR preheat, at all cell positions",
    "Ultrasonic fluxing",
    "Dual Drop-Jet / Ultrasonic fluxing",
  ],
  "Orissa Synchrodex": [
    "Ultrasonic fluxing",
    "Dual Drop-Jet / ultrasonic fluxing",
    "Top-side instant IR preheat",
    "Closed loop pyrometer temperature control",
    "Bottom-side hot Nitrogen selective preheat",
    "Laser PCB warp correction",
    "Solder reel identification",
    "Solder bath coding - identifies correct bath for program",
    "Encoders on X, Y and Z axis",
    "Nitrogen generator",
  ],
  "Orissa Synchrodex Pro": [
    "High definition process viewing camera",
    "Top side IR preheat, with closed-loop control",
    "Motorised underside IR preheat, at all cell positions",
    "Ultrasonic fluxing",
    "Dual Drop-Jet / Ultrasonic fluxing",
    "Laser-based PCB warp correction",
    "Solder reel identification",
    "Nitrogen generator",
  ],
  "Fusion Layouts": [
    "Ultrasonic fluxing",
    "Dual Drop-Jet / ultrasonic fluxing",
    "Top-side instant IR preheat",
    "Bottom-side IR preheat",
    "Closed loop pyrometer temperature control",
    "Large solder bath for dedicated single dip applications",
    "Laser PCB warp correction",
    "1.5mm micro nozzle",
    "Solder reel identification",
    "Auto-nozzle conditioning system",
    "Larger PCB handling size",
    "Nitrogen generator",
  ],
};

export const PRODUCT_DETAILS = {
  default: {
    product: { img: Product1InOptions },
    options: [
      {
        id: 1,
        name: "MES (Manufacturing Equipment Systems)",
        isDisabled: false,
        link: "",
        pointColor: "gray",
        pointFromRight: true,
        xPos: 94,
        yPos: 75,
        dashTransform: "rotate(30deg)",
        width: 482,
      },
      {
        id: 2,
        name: "Automatic Fiducial Correction",
        isDisabled: false,
        pointFromRight: true,
        link: "",
        pointColor: "red",
        xPos: 94,
        yPos: 151,
      },
      {
        id: 3,
        name: "Auto level detect and top up",
        isDisabled: false,
        pointFromRight: true,
        link: "",
        pointColor: "gray",
        xPos: 94,
        yPos: 227,
      },
      {
        id: 4,
        name: "Standard or Compact",
        isDisabled: false,
        pointFromRight: true,
        link: "",
        pointColor: "gray",
        xPos: 94,
        yPos: 303,
      },
      {
        id: 5,
        name: "Extraction Anamometer",
        isDisabled: false,
        pointFromRight: true,
        link: "",
        pointColor: "gray",
        xPos: 94,
        yPos: 379,
      },
      {
        id: 6,
        name: "Flux Flow Monitoring",
        isDisabled: false,
        pointFromRight: true,
        link: "",
        pointColor: "gray",
        xPos: 94,
        yPos: 455,
      },
      {
        id: 7,
        name: "Optical Flux Presence Sensor",
        isDisabled: false,
        pointFromRight: true,
        link: "",
        pointColor: "gray",
        xPos: 94,
        yPos: 531,
      },
      {
        id: 8,
        name: "Bottom Side Pre-Heat",
        isDisabled: false,
        pointFromRight: true,
        link: "",
        pointColor: "red",
        xPos: 94,
        yPos: 607,
      },
      {
        id: 9,
        name: "PCB Warpage Correction",
        isDisabled: false,
        pointFromRight: true,
        link: "",
        pointColor: "red",
        xPos: 94,
        yPos: 683,
      },
      {
        id: 10,
        name: "Internal Monitoring of Machine Temperature",
        isDisabled: false,
        pointFromRight: true,
        link: "",
        pointColor: "gray",
        xPos: 94,
        yPos: 759,
        dashTransform: "rotate(-30deg)",
        width: 482,
      },
      {
        id: 11,
        name: "Electromagnetic Pump Assembly 1.2mm Micro Nozzle",
        isDisabled: false,
        link: "",
        pointColor: "red",
        rightPos: 94,
        yPos: 75,
        dashTransform: "rotate(-30deg)",
        width: 482,
      },
      {
        id: 12,
        name: "Jetwave",
        isDisabled: true,
        link: "",
        pointColor: "red",
        rightPos: 94,
        yPos: 151,
      },
      {
        id: 13,
        name: "Closed-Loop Top Side Pyro Control",
        isDisabled: false,
        link: "",
        pointColor: "red",
        rightPos: 94,
        yPos: 227,
      },
      {
        id: 14,
        name: "Ring Heater",
        isDisabled: false,
        link: "",
        pointColor: "gray",
        rightPos: 94,
        yPos: 303,
      },
      {
        id: 15,
        name: "Dual Bath",
        isDisabled: false,
        link: "",
        pointColor: "gray",
        rightPos: 94,
        yPos: 379,
      },
      {
        id: 16,
        name: "Nitrogen Mass Flow Meter",
        isDisabled: false,
        link: "",
        pointColor: "red",
        rightPos: 94,
        yPos: 455,
      },
      {
        id: 17,
        name: "Barcode Reader",
        isDisabled: false,
        link: "",
        pointColor: "red",
        rightPos: 94,
        yPos: 531,
      },
      {
        id: 18,
        name: "Additional Flux Head",
        isDisabled: false,
        link: "",
        pointColor: "red",
        rightPos: 94,
        yPos: 607,
      },
      {
        id: 19,
        name: "Top Side Pre-Heat",
        isDisabled: false,
        link: "",
        pointColor: "red",
        rightPos: 94,
        yPos: 683,
      },
      {
        id: 20,
        name: "Closed-Loop Bottom Side Pyro Control",
        isDisabled: false,
        link: "",
        pointColor: "red",
        rightPos: 94,
        yPos: 759,
        dashTransform: "rotate(30deg)",
        width: 482,
      },
    ],
  },
};

export const MONITORING_OPTIONS = {
  default: [
    "Flux spray monitoring",
    "Flux flow monitoring",
    "Flux spray & flow monitoring",
  ],
  "Orissa Synchrodex": [
    "Flux presence sensor - thermistor style",
    "Flux spray, flow and spray & flow",
    "O2 ppm",
    "Nitrogen flow",
  ],
  "Orissa Synchrodex Pro": [
    "Flux spray, flow and spray & flow",
    "Pump rpm",
    "O2 ppm",
    "Nitrogen flow",
  ],
  "Fusion Layouts": [
    "Flux presence sensor - thermistor style",
    "Flux spray, flow and spray & flow",
    "O2 ppm",
    "Nitrogen flow",
  ],
};

export const PRODUCT_SLIDES = {
  default: [
    {
      type: "image",
      description:
        "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
      content: Fusion4Product,
    },
    {
      type: "image",
      description:
        "The fluxer/preheat combo operates in tandem with the selective soldering unit and can be configured with either Drop-Jet or ultrasonic flux heads and both top and bottom side IR preheat functions. Twin pitch fluxheads are available for further cycle time reductions.",
      content: JadeS200MK2Product,
    },
    {
      type: "image",
      description:
        "This compact modular in-line system ulitises the same design concept as the new generation Synchrodex Pro soldering cell and offers the user significantly reduced process time when compared to using a single soldering unit with on-board fluxing and preheat functions.",
      content: Fusion4Product,
    },
    {
      type: "image",
      description:
        "The fluxer/preheat combo operates in tandem with the selective soldering unit and can be configured with either Drop-Jet or ultrasonic flux heads and both top and bottom side IR preheat functions. Twin pitch fluxheads are available for further cycle time reductions.",
      content: JadeS200MK2Product,
    },
  ],
  fluxing: [
    {
      type: "image",
      name: "Thermal Flux Sensing System",
      description:
        "The Thermal Flux Sensing system uses a PCB mounted thermistor. A controlled current is applied to the thermistor. This heats up the thermistor. When flux is sprayed and it hits the thermistor the thermistor cools slightly and this results in a change in the current. This change is read by the machine as ‘flux present’.",
      content: FluxMonitor1,
    },
    {
      type: "image",
      name: "Optical Flux Plume System",
      description:
        "The Optical Flux Plume system uses a sensor mounted opposite an LED light source. A sponge pad is mounted above the Flux Plume Sensor assembly to absorb the flux spray during Plume Sensing operation. The Flux Head is positioned below and between the two sensors and the signal is first measured before the flux is fired. This measurement is held in a 'sample and hold circuit', while a second measurement is taken during flux firing. The two readings are then compared and the difference is used as a measurement of the Flux Plume. Threshold levels can be set at to provide an error message when the Flux Plume Reading is out of the threshold range.",
      content: FluxMonitor2,
    },
    {
      type: "image",
      name: "Flux Flow Monitoring system",
      description:
        "The Flux Flow Monitoring system uses a Bronkhorst Digital Liqui-Flow meter. The Liquid Mass Flow Meter is capable of measuring low flow rates of (6-200g/hour). This provides flow rate data to the Pillarhouse Selective Soldering System. This data is then logged for each process cycle.",
      content: FluxFlowMeter,
    },
  ],
  soldering: [
    {
      type: "video",
      name: "Dual Solder Pots",
      description:
        "Dual solder pots on independent Z axis to allow mixed nozzles within a process program.",
      content: TwinBathVideo,
    },
    {
      type: "video",
      name: "Wave Height",
      description:
        "Automatically monitors the solder wave and maintains its height. Adjusts pump speed to compensate any fluctuation in wave height.",
      content: WaveHeightVideo,
    },
    {
      type: "video",
      name: "Solder Level Detect/Top-up",
      description:
        "Automatically monitors the solder level and adds solder when required.",
      content: SolderFeederVideo,
    },
    {
      type: "image",
      name: "Pot Coding/Solder Reel Coding",
      description:
        "Solder baths are individually tagged so that incorrect solder alloys cannot be selected with respect to any specific customer generated program.",
      content: ReelDetectImg,
    },
    {
      type: "video",
      name: "Thermal Nozzle Calibration",
      description:
        "Using an imprint of the solder nozzle, on thermal paper, and with a scaled grid the Operator can manually correct the solder nozzle position to within 0.1mm.",
      content: ThermalNozzleCalVideo,
    },
    {
      type: "video",
      name: "Heated Trollies",
      description:
        "Allows bath maintenance away from the machine and maintains sufficient heat in the solder bath for a more rapid changeover.",
      content: BathTrolley,
    },
    {
      type: "image",
      name: "Pump RPM Monitoring",
      description:
        "The Pump RPM monitor system monitors the speed of the solder pump. If a problem arises that affects the speed of the impellor, the sensing system will detect this change, stop the pump running and display an error message.",
      content: RPMSensorImg,
    },
    {
      type: "video",
      name: "Auto Nozzle Conditioning",
      description:
        "Reduces the need for manual re-tinning on the solder nozzle, resulting in the reduction of nozzle de-wetting and increases finished product quality.",
      content: NozzleConditioningVideo,
    },
    {
      type: "video",
      name: "Nitrogen Purity Analyser",
      description: "Measures oxygen level in PPM at the solder nozzle.",
      content: O2analyserVideo,
    },
  ],
  other: [
    {
      type: "video",
      name: "Board Warp",
      description:
        "The Pillarhouse board warp correction system uses a precision laser height sensor to measure the height of the board, to better than 0.1mm, at pre-programmed coordinates and adjusts the movement of the solder nozzle to compensate for height differences. Because the board can distort more during the soldering process the height measurements can be programmed in sequence. The software automatically selects the nearest board warp measurement to make the correction.",
      content: BoardWarpVideo,
    },
    {
      type: "image",
      name: "Fiducial Correction",
      description: "Corrects board positional errors in X, Y and theta.",
      content: Fiducial,
    },
    {
      type: "image",
      name: "Barcodes and Board Traceability",
      description:
        "Allows automatic process program selection and board traceability, both locally and remotely.",
      content: BarcodesAndTraceAbility,
    },
  ],
  "Synchrodex Pro 1600 Dual Bath Solder Module": [
    {
      type: "image",
      description:
        "Introducing the Pillarhouse Synchrodex Pro, a versatile selective soldering system designed to meet high-value PCB manufacturing needs. It features modular flexibility with three platform sizes accommodating PCBs from 420mm x 610mm (16” x 24”) up to 1200mm x 610mm (45” x 24”). Choose from multi-zone IR or full convection heating options, including a high-speed convection stacker, ensuring high thermal capability for precise soldering.",
      content: SP1600DBATHImg,
    },
    {
      type: "video",
      description:
        "With ample top side clearance and a robust 36kg weight capacity, the Synchrodex Pro handles complex assemblies effortlessly. Its modular design allows for easy scalability, while intuitive GUI and centralized programming support (Gerber, photo, and native camera programming) streamline operation. Ideal for customizable build-to-suit configurations, this system caters to diverse production requirements, delivering consistent high-quality results in demanding environments.",
      content: SP1600DBATHVideo1,
    },
    {
      type: "video",
      description:
        "Introducing the Synchrodex Pro, available in 1060mm and 1600mm frame formats. Our advanced system can seamlessly integrate into high-speed, high-flexibility production lines, or offer a combination of both for maximum efficiency. Each 1600mm soldering module can be equipped with single or dual solder baths and can handle soldering tasks up to 1200mm x 610mm (45” x 24”). Watch our animation to see how the Synchrodex Pro excels in versatility. Easily switch modules to suit your specific needs, whether it’s closed-loop high-intensity IR, full convection, or high-speed stacker convection – all within the same production line. Experience the ultimate in adaptability and performance with the Synchrodex Pro.",
      content: SP1600DBATHVideo2,
    },
  ],
  "Orissa Fusion 4": [
    {
      type: "image",
      description:
        "Introducing the Fusion 4, the powerhouse for high-efficiency PCB production. Built for precision and speed, it blends advanced technology with easy operation. Its sleek design and intuitive controls streamline even complex tasks, while robust construction ensures maximum output without sacrificing quality. Upgrade your production with the Fusion 4—where innovation powers performance.",
      content: F4ReplaceBadColoringImg,
    },
    {
      type: "video",
      description:
        "Capable of handling various PCB sizes and components, the Fusion 4 can be adapted for different types of projects, making it suitable for diverse applications across multiple industries. Equipped with PillarCOMM.NET software, the machine allows for automated programming and process control. This intuitive interface makes it easier for operators to monitor the complete process, ensuring stringent quality standards and enhancing reliability.",
      content: F4ReplaceWithChinaVideo,
    },
  ],
};

export const OTHER_CHOICES = [
  {
    name: "Pilot",
    forwardTo: "/product?name=pilot",
    description:
      "Entry-level, benchtop, single point selective soldering system",
    content: Pilot,
  },
  {
    name: "JADE MKII",
    forwardTo: "/product?name=Jade MKII",
    description:
      "Elementary, flexible, single point selective soldering system",
    content: JadeMK2,
  },
  {
    name: "QUADRON (PLC)",
    forwardTo: "/product?name=Quadron",
    description: "High specification, PLC, rotary soldering machine",
    content: QuadronPLC,
  },
  {
    name: "JADE MKIV",
    forwardTo: "/product?name=Jade MKIV",
    description:
      "Entry-level, benchtop, single point selective soldering system",
    content: JadeMK4,
  },
  {
    name: "JADE PRO",
    forwardTo: "/product?name=Jade Pro",
    description:
      "Enhanced, entry-level, single point selective soldering system",
    content: JadePro,
  },
  {
    name: "JADE PRODEX",
    forwardTo: "/product?name=JADE Prodex",
    description:
      "Ultra-flexible, offline, multi-platform, quick load twin PCB rotary table selective soldering system",
    content: JadeProDex,
  },
];

export const OPTIONAL_FEATURES_BY_KEY = {
  gray5: {
    id: 1,
    name: "MES (Manufacturing Equipment Systems)",
    isDisabled: false,
    link: "",
    pointFromRight: true,
    xPos: 94,
    yPos: 75,
    dashTransform: "rotate(30deg)",
    width: 482,
    hasDashFromPoint: true,
  },
  red9: {
    id: 2,
    name: "Automatic Fiducial Correction",
    isDisabled: false,
    pointFromRight: true,
    link: "",
    xPos: 94,
    yPos: 151,
    hasDashFromPoint: true,
  },
  gray9: {
    id: 3,
    name: "Auto Level Detect and Top Up",
    isDisabled: false,
    pointFromRight: true,
    link: "",
    xPos: 94,
    yPos: 227,
    hasDashFromPoint: true,
  },
  gray6: {
    id: 4,
    name: "Automatic rework Integration",
    isDisabled: false,
    pointFromRight: true,
    link: "",
    xPos: 94,
    yPos: 303,
    hasDashFromPoint: true,
  },
  gray8: {
    id: 5,
    name: "Auto Nozzle Tinning",
    isDisabled: false,
    pointFromRight: true,
    link: "",
    xPos: 94,
    yPos: 379,
    hasDashFromPoint: true,
  },
  gray4: {
    id: 6,
    name: "Flux Flow Monitoring",
    isDisabled: false,
    pointFromRight: true,
    link: "",
    xPos: 94,
    yPos: 455,
    hasDashFromPoint: true,
  },
  gray3: {
    id: 7,
    name: "Optical Flux Presence Sensor",
    isDisabled: false,
    pointFromRight: true,
    link: "",
    xPos: 94,
    yPos: 531,
    hasDashFromPoint: true,
  },
  red2: {
    id: 8,
    name: "Bottom Side Pre-Heat",
    isDisabled: false,
    pointFromRight: true,
    link: "",
    xPos: 94,
    yPos: 607,
    hasDashFromPoint: true,
  },
  red4: {
    id: 9,
    name: "PCB Warpage Correction",
    isDisabled: false,
    pointFromRight: true,
    link: "",
    xPos: 94,
    yPos: 683,
    hasDashFromPoint: true,
  },
  gray7: {
    id: 10,
    name: "Internal Monitoring of Machine Temperature",
    isDisabled: false,
    pointFromRight: true,
    link: "",
    xPos: 94,
    yPos: 759,
    dashTransform: "rotate(-30deg)",
    width: 482,
    hasDashFromPoint: true,
  },
  red5: {
    id: 11,
    name: "Electromagnetic Pump",
    isDisabled: false,
    link: "",
    rightPos: 94,
    yPos: 75,
    dashTransform: "rotate(-30deg)",
    width: 482,
    hasDashFromPoint: true,
  },
  red6: {
    id: 12,
    name: "Jet Wave or Custom Nozzles",
    isDisabled: true,
    link: "",
    rightPos: 94,
    yPos: 151,
    hasDashFromPoint: true,
  },
  red12: {
    id: 13,
    name: "Nozzle Verificaton",
    isDisabled: true,
    link: "",
    rightPos: 94,
    yPos: 227,
    hasDashFromPoint: true,
  },
  red1: {
    id: 13,
    name: "Closed-Loop Top Side Pyro Control",
    isDisabled: false,
    link: "",
    rightPos: 94,
    yPos: 227,
    hasDashFromPoint: true,
  },
  gray1: {
    id: 14,
    name: "Ring Heater",
    isDisabled: false,
    link: "",
    rightPos: 94,
    yPos: 303,
    hasDashFromPoint: true,
  },
  gray2: {
    id: 15,
    name: "Dual Bath",
    isDisabled: false,
    link: "",
    rightPos: 94,
    yPos: 379,
    hasDashFromPoint: true,
  },
  red11: {
    id: 16,
    name: "Thermal Nozzle Calibration",
    isDisabled: false,
    link: "",
    rightPos: 94,
    yPos: 455,
    hasDashFromPoint: true,
  },
  gray10: {
    id: 17,
    name: "Barcode Reader",
    isDisabled: false,
    link: "",
    rightPos: 94,
    yPos: 531,
    hasDashFromPoint: true,
  },
  red10: {
    id: 18,
    name: "1.2mm Micro Nozzle",
    isDisabled: false,
    link: "",
    xPos: 634,
    yPos: 28,
    withoutDashes: true,
  },
  red7: {
    id: 19,
    name: "Top Side IR  Pre-Heat",
    isDisabled: false,
    link: "",
    rightPos: 94,
    yPos: 607,
    hasDashFromPoint: true,
  },
  red8: {
    id: 19,
    name: "Drop Jet/Ultrasonic Fluxing-multiple heads",
    isDisabled: false,
    link: "",
    rightPos: 94,
    yPos: 683,
    hasDashFromPoint: true,
  },
  red3: {
    id: 20,
    name: "Closed-Loop Bottom Side Pyro Control",
    isDisabled: false,
    link: "",
    rightPos: 94,
    yPos: 759,
    dashTransform: "rotate(30deg)",
    width: 482,
    hasDashFromPoint: true,
  },
};

export const FEATURES = {
  default: [
    {
      xPos: 262,
      yPos: 85,
      text: "IPC-2591 (CFX), Smart Factory ready",
    },
    {
      xPos: 189,
      yPos: 212,
      text: "Colour programming camera",
    },
    {
      xPos: 261,
      yPos: 330,
      text: "Drop Jet fluxer",
    },
    {
      xPos: 160,
      yPos: 454,
      text: "Flux level sensor",
    },
    {
      xPos: 305,
      yPos: 537,
      text: "Multiple level password protection",
    },
    {
      xPos: "30%",
      yPos: 627,
      text: "508mm x 508mm (20' x 20') and 610mm x 610mm (24' x 24') board handling sizes",
    },
    {
      xPos: 901,
      yPos: 512,
      text: "SMEMA compatible",
    },
    {
      xPos: 958,
      yPos: 396,
      text: "New PillarCOMM control software",
    },
    {
      xPos: 920,
      yPos: 250,
      text: "Internal fume extraction",
    },
    {
      xPos: 1075,
      yPos: 165,
      text: "Day-to-day service kit",
    },
    {
      xPos: 964,
      yPos: 80,
      text: "DC drives on all axes",
    },
  ],
  "Orissa Synchrodex": [
    {
      xPos: 162,
      yPos: 85,
      text: "In-line motor driven auto width adjust through feed synchronous movement conveyor",
    },
    {
      xPos: 189,
      yPos: 212,
      text: "Conveyor side clamping",
    },
    {
      xPos: 201,
      yPos: 330,
      text: "Integral PC and machine mounted monitor",
    },
    {
      xPos: 160,
      yPos: 454,
      text: "Motorised auto-solder top-up (wire feed) & solder level detect",
    },
    {
      xPos: 305,
      yPos: 537,
      text: "Heated inerted Nitrogen system",
    },
    {
      xPos: "30%",
      yPos: 637,
      text: "Titanium Drop-Jet fluxer",
    },
    {
      xPos: 901,
      yPos: 512,
      text: "Set of AP solder nozzle tips",
    },
    {
      xPos: 958,
      yPos: 396,
      text: "Internal fume extraction",
    },
    {
      xPos: 920,
      yPos: 250,
      text: "Colour programming camera",
    },
    {
      xPos: 1075,
      yPos: 185,
      text: "Solder wave height measurement and correction",
    },
    {
      xPos: 964,
      yPos: 80,
      text: "Pump rpm",
    },
    {
      xPos: 62,
      yPos: 15,
      text: "Auto-nozzle conditioning system",
    },
    {
      xPos: 139,
      yPos: 142,
      text: "Thermal nozzle calibration system using integrated setting camera",
    },
    {
      xPos: 111,
      yPos: 270,
      text: "Windows® based PillarCOMM ‘Point & Click’ interface",
    },
    {
      xPos: 130,
      yPos: 404,
      text: "Light stack",
    },
    {
      xPos: 80,
      yPos: 587,
      text: "Auto fiducial recognition and correction system",
    },
    {
      xPos: 80,
      yPos: 647,
      text: "Flux level sensor",
    },
    {
      xPos: 901,
      yPos: 602,
      text: "Multiple level password protection",
    },
    {
      xPos: 988,
      yPos: 456,
      text: "PillarPAD offline programming system",
    },
    {
      xPos: 990,
      yPos: 320,
      text: "SMEMA compatible",
    },
    {
      xPos: 775,
      yPos: 135,
      text: "Process viewing camera with record feature",
    },
    {
      xPos: 1164,
      yPos: 80,
      text: "Lead-free capability",
    },
    {
      xPos: 964,
      yPos: 15,
      text: "Day-to-day service kit",
    },
  ],
  "Orissa Synchrodex Pro": [
    {
      xPos: 162,
      yPos: 85,
      text: "508mm x 508mm (20' x 20') and 610mm x 610mm (24' x 24') board handling sizes",
    },
    {
      xPos: 189,
      yPos: 212,
      text: "Interchangeable Mechanical or Magnetic solder bath and pump",
    },
    {
      xPos: 201,
      yPos: 330,
      text: "Lead-free solder capability",
    },
    {
      xPos: 160,
      yPos: 454,
      text: "Inerted Nitrogen system",
    },
    {
      xPos: 305,
      yPos: 537,
      text: "Set of AP style solder nozzle tips",
    },
    {
      xPos: "30%",
      yPos: 637,
      text: "Automatic nozzle conditioning system",
    },
    {
      xPos: 901,
      yPos: 512,
      text: "Thermal nozzle calibration",
    },
    {
      xPos: 958,
      yPos: 396,
      text: "Solder level detect and motorised wire feeder",
    },
    {
      xPos: 920,
      yPos: 250,
      text: "Wave height correction",
    },
    {
      xPos: 1075,
      yPos: 185,
      text: "DC drives on all axes",
    },
    {
      xPos: 964,
      yPos: 80,
      text: "Colour programming camera",
    },
    {
      xPos: 62,
      yPos: 15,
      text: "Automatic fiducial correction",
    },
    {
      xPos: 139,
      yPos: 142,
      text: "Drop Jet fluxer",
    },
    {
      xPos: 111,
      yPos: 270,
      text: "Internal fume extraction",
    },
    {
      xPos: 130,
      yPos: 404,
      text: "Light stack",
    },
    {
      xPos: 80,
      yPos: 587,
      text: "Integral PC and monitor",
    },
    {
      xPos: 80,
      yPos: 647,
      text: "New PillarCOMM control software",
    },
    {
      xPos: 901,
      yPos: 602,
      text: "New PillarCOMM control software",
    },
    {
      xPos: 988,
      yPos: 456,
      text: "Multiple level password protection",
    },
    {
      xPos: 990,
      yPos: 320,
      text: "IPC-2591 (CFX), Smart Factory ready",
    },
  ],
  "Fusion Layouts": [
    {
      xPos: 162,
      yPos: 85,
      text: "Integral PC and machine mounted Titanium monitor",
    },
    {
      xPos: 189,
      yPos: 212,
      text: "In-line motor driven width adjust through feed conveyor",
    },
    {
      xPos: 201,
      yPos: 330,
      text: "Conveyor side clamping",
    },
    {
      xPos: 160,
      yPos: 454,
      text: "DC servo drives with encoders on X, Y & Z axis",
    },
    {
      xPos: 305,
      yPos: 537,
      text: "Inerted Nitrogen system",
    },
    {
      xPos: "30%",
      yPos: 637,
      text: "Internal fume extraction",
    },
    {
      xPos: 901,
      yPos: 512,
      text: "Titanium Drop-Jet fluxer",
    },
    {
      xPos: 958,
      yPos: 396,
      text: "Motorised wire feed auto solder top-up & solder level detect",
    },
    {
      xPos: 920,
      yPos: 250,
      text: "Solder wave height measurement and correction system",
    },
    {
      xPos: 1075,
      yPos: 185,
      text: "Solder bath coding – identifies correct bath for program",
    },
    {
      xPos: 964,
      yPos: 80,
      text: "Thermal nozzle calibration system using integrated setting camera",
    },
    {
      xPos: 62,
      yPos: 15,
      text: "Auto-nozzle conditioning system",
    },
    {
      xPos: 139,
      yPos: 142,
      text: "Pump rpm",
    },
    {
      xPos: 111,
      yPos: 270,
      text: "Set of AP solder nozzle tips",
    },
    {
      xPos: 130,
      yPos: 404,
      text: "Colour programming camera",
    },
    {
      xPos: 80,
      yPos: 587,
      text: "Process viewing camera",
    },
    {
      xPos: 80,
      yPos: 647,
      text: "Automatic fiducial correction system",
    },
    {
      xPos: 901,
      yPos: 602,
      text: "Multiple level password protection",
    },
    {
      xPos: 988,
      yPos: 456,
      text: "Light stack",
    },
    {
      xPos: 990,
      yPos: 320,
      text: "Flux level sensor",
    },
    {
      xPos: 901,
      yPos: 602,
      text: "PillarCOMM Windows® based ‘Point & Click’ interface",
    },
    {
      xPos: 988,
      yPos: 456,
      text: "Lead-free compatible",
    },
    {
      xPos: 990,
      yPos: 320,
      text: "Day-to-day service kit",
    },
  ],
};

export const STANDARD_FEATURES_BY_KEY = {
  17: {
    xPos: 20,
    yPos: 6,
    text: "Live Monitoring of Machine Downtime via Logfiles",
  },
  2: {
    xPos: 99,
    yPos: 486,
    text: "Auto Solder Top-up (Wire Feed) & Solder Level Detect",
  },
  1: {
    xPos: 281,
    yPos: 636,
    text: "Inerted Nitrogen System",
  },
  3: {
    xPos: 190,
    yPos: 135,
    text: "Titanium Drop Jet Fluxer - 200 Micron",
  },
  13: {
    xPos: 917,
    yPos: 386,
    text: "Automatic Electromagnetic Door Locks",
  },
  9: {
    xPos: 585,
    yPos: 636,
    text: "Set of AP Solder Tips (2.5mm, 3mm, 3.5mm, 4mm, 5mm, 6mm, 8mm, 10mm & 12mm)",
  },
  10: {
    xPos: 383,
    yPos: 562,
    text: "Standard Production Data Export (CSV File with Configurable Board Process Data)",
  },
  14: {
    xPos: 156,
    yPos: 386,
    text: "Automatic Board Side Clamping",
  },
  15: {
    xPos: 915,
    yPos: 294,
    text: "Software Intelligent Pre Board Stop Conveyor Sensing",
  },
  16: {
    xPos: 633,
    yPos: 486,
    text: "Rapid Bath Interchnage Capability",
  },
  11: {
    xPos: 161,
    yPos: 294,
    text: "Machine Mounted Monitor and PC",
  },
  6: {
    xPos: 128,
    yPos: 59,
    text: "Thermal Nozzle Calibration System using Integrated Setting Camera (requires manual correction)",
  },
  4: {
    xPos: 952,
    yPos: 135,
    text: "Colour programming camera",
  },
  5: {
    xPos: 1003,
    yPos: 486,
    text: "Auto Fiducial Correction System",
  },
  7: {
    xPos: 588,
    yPos: 135,
    text: "Solder Wave Height Measurement",
  },
  8: {
    xPos: 980,
    yPos: 59,
    text: "Process Viewing Camera",
  },
  12: {
    xPos: 215,
    yPos: 210,
    text: "Offline Programming Package",
  },
};
