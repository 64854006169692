const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;
const VIDEO_BASE_URL = process.env.REACT_APP_VIDEO_BASE_URL;

export const ROUTES_WITH_BANNER_IMAGES = [
  "medical",
  "orissa fusion 4",
  "synchrodex pro 1600 dual bath solder module",
];

export const BANNER_DETAILS = {
  default: [
    {
      details: {
        Installations: "800+",
        "Worked Hours": "4.5k+",
        "Produced Boards": "2k+",
      },
      title: "Orissa Synchrodex Pro Combo",
      backgroundImg: `${IMAGE_BASE_URL}products/fusion-4.png`,
    },
    {
      details: {
        Installations: "40+",
        Effectiveness: "97%",
        "Market Share": "28%",
      },
      titlePrefix: "Market Sector",
      title: "Electric Vehicles",
      backgroundImg: `${IMAGE_BASE_URL}market-sector/banner-market-sector-img.png`,
    },
    {
      details: {
        Installations: "800+",
        "Worked Hours": "4.5k+",
        "Produced Boards": "2k+",
      },
      title: "Orissa Synchrodex Pro Combo",
      backgroundImg: `${IMAGE_BASE_URL}products/fusion-4.png`,
    },
  ],
  market: [
    {
      details: {
        Installations: "800+",
        "Worked Hours": "4.5k+",
        "Produced Boards": "2k+",
      },
      title: "Orissa Synchrodex Pro Combo",
      backgroundImg: `${IMAGE_BASE_URL}market-sector/market-banner-1.png`,
    },
    {
      details: {
        Installations: "40+",
        Effectiveness: "97%",
        "Market Share": "28%",
      },
      titlePrefix: "Market Sector",
      title: "Electric Vehicles",
      backgroundImg: `${IMAGE_BASE_URL}market-sector/market-banner-2.png`,
    },
    {
      details: {
        Installations: "800+",
        "Worked Hours": "4.5k+",
        "Produced Boards": "2k+",
      },
      title: "Orissa Synchrodex Pro Combo",
      backgroundImg: `${IMAGE_BASE_URL}market-sector/market-banner-3.png`,
    },
  ],
  medical: [
    {
      backgroundImg: `${IMAGE_BASE_URL}market-sector/medical_banner_1.jpg`,
    },
  ],
  "orissa fusion 4": [
    {
      backgroundImg: `${IMAGE_BASE_URL}products/orissa-fusion-4/F4-FHSS-ISO.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/orissa-fusion-4/F4 Conveyor Outside.jpg`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/orissa-fusion-4/F4-render-doors-down.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/orissa-fusion-4/F4-render-front-view.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/orissa-fusion-4/F4-render-top-view.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/orissa-fusion-4/F4-replace-bad-coloring-const-angle.jpg`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/orissa-fusion-4/F4-top-side-pre-heat-replace-too-dark.jpg`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/orissa-fusion-4/F4-conveyor-replace-different-colors.jpg`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/orissa-fusion-4/F4-example-config-replace-diff-color.jpg`,
    },
  ],
  "synchrodex pro 1600 dual bath solder module": [
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1600dbath/SP1600 DBATH Solder Baths.jpg`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1600dbath/SP1600 DBATH.jpg`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1600dbath/SP1600 DBATH.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1600dbath/SP1600 DBATH ISO 01.07.24.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1600dbath/SP1600 DBATH ISO BSPH 01.07.24.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1600dbath/SP1600 DBATH DOORS DOWN NO MONITOR.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1600dbath/SP1600 DBATH DOORS UP NO MONITOR.png`,
    },
    {
      backgroundImg: `${IMAGE_BASE_URL}products/sp1600dbath/SYN PRO LINE LAYOUT 26.09.23 FRONT DOORS OPEN.jpg`,
    },
  ],
};

export const BANNER_TXT_DETAILS = {
  market: {
    details: {
      Installations: "40+",
      Effectiveness: "97%",
      "Market Share": "28%",
    },
    titlePrefix: "Market Sector",
    title: "Electric Vehicles",
  },
  "automotive/ev": {
    details: {
      "Countries mfg automotive product with Pillarhouse systems": "18",
      "supplier to all major automotive manufacturers": "100%",
      "supplying automotive industry with soldering solutions": "1977-to today",
    },
  },
  medical: {
    details: {
      Installations: "40+",
      Effectiveness: "97%",
      "Market Share": "28%",
    },
  },
  product: {
    details: {
      Installations: "800+",
      "Worked Hours": "4.5k+",
      "Produced Boards": "2k+",
    },
    title: "Orissa Synchrodex Pro Combo",
  },
  "synchrodex pro 1600 dual bath solder module": {
    details: {
      "PCB Roller Conveyor Capacity": "36kg",
      "Max PCB Size": "1200mm x 610mm (45” x 24”)",
      "Parallel PCB Processing": "2x",
    },
  },
  "orissa fusion 4": {
    details: {
      "User Base Breakdown":
        "30% EMS, 20% Industrial, 15% Consumer, 15% Telecommunications + Medical, 10% Aerospace + Defense, 10% Other",
      "Number of Solder Baths": "<=4",
      "Bath Change Method": "Auto Cart Change x4",
    },
  },
};
