import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "hooks/useTranslation";
import {
  EXISTING_ENHANCEMENTS_KEYS,
  EXISTING_KEYS,
  FEATURES,
  FEATURES_INFOS,
  MONITORING_OPTIONS,
  PRODUCT_SLIDES,
  SYSTEM_OPTIONS,
} from "./constants";
import { FeatureItem } from "./components/FeatureItem";
import { InformativeBlock } from "shared/components/InformativeBlock";
import { HorizontalProductSwipe } from "shared/components/HorizontalProductSwipe";
import { ProductItem } from "shared/components/ProductItem";
import { SectionTitleLeftWaves } from "shared/components/SectionTitleLeftWaves";
import styles from "./Enhancements.module.scss";
import {
  IconMonitoringOptions,
  IconSystemOptions,
  IconThreeWavesToRight,
  IconCurrentLocationGray,
} from "icons";
import { productsListSelector } from "reduxStore/slices/selectors";
import { shuffleArray } from "utils/arrayUtils";

export const Enhancements = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { search, state } = location;
  const queryParams = new URLSearchParams(search);
  const name = queryParams.get("name");
  const chosenItemOptName = queryParams.get("option");

  const productsList = useSelector(productsListSelector);

  const filteredProducts = productsList.filter(
    product => product.name.toLowerCase() !== name?.toLowerCase()
  );

  const randomProducts = shuffleArray(filteredProducts).slice(0, 6);

  const [defaultActiveSwiperIdx, setDefaultActiveSwiperIdx] = useState(null);
  const horizontalSwiperRef = useRef(null);

  const { translate } = useTranslation();

  const contentKey = EXISTING_KEYS[name] || "default";
  const enhancementsContentKey = EXISTING_ENHANCEMENTS_KEYS[name] || "default";

  useEffect(() => {
    if (state?.scrollToSwiper && horizontalSwiperRef.current) {
      horizontalSwiperRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [state]);

  useEffect(() => {
    if (name && chosenItemOptName) {
      horizontalSwiperRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      const selectingSwiperItemIdx = PRODUCT_SLIDES[
        enhancementsContentKey
      ].findIndex(item => item?.option === chosenItemOptName?.toLowerCase());
      setDefaultActiveSwiperIdx(selectingSwiperItemIdx);
      navigate(location.pathname + location.search, {
        replace: true,
        state: {},
      });
    }
  }, [name, chosenItemOptName]);

  return (
    <div className={styles.container}>
      <div className={styles.featuresBlock}>
        <div className={styles.features}>
          <h1 className={styles.title}>
            {translate("Standard Features")}
            <IconThreeWavesToRight className={styles.titleIcon} />
          </h1>
          {FEATURES[contentKey].map((feat, idx) => (
            <FeatureItem
              key={idx}
              xPos={feat.xPos}
              yPos={feat.yPos}
              text={feat.text}
            />
          ))}
        </div>
      </div>
      <div className={styles.infos}>
        {FEATURES_INFOS[contentKey].map((info, idx) => (
          <InformativeBlock key={idx} text={info} />
        ))}
      </div>
      <div className={styles.systemOptions}>
        <SectionTitleLeftWaves title={translate("System Options")} />
        <div className={styles.sysOptsContent}>
          <div className={styles.sysOptsColumn}>
            <div className={styles.colTitleBlock}>
              <IconSystemOptions />
              <p className={styles.colTitle}>{translate("System Options")}</p>
            </div>
            {SYSTEM_OPTIONS[contentKey].map((info, idx) => (
              <div key={idx} className={styles.sysOptionRow}>
                <IconCurrentLocationGray />
                <p className={styles.sysOptTxt}>{info}</p>
              </div>
            ))}
          </div>
          <div className={styles.sysOptsColumn}>
            <div className={styles.colTitleBlock}>
              <IconMonitoringOptions />
              <p className={styles.colTitle}>
                {translate("Monitoring Options")}
              </p>
            </div>
            {MONITORING_OPTIONS[contentKey].map((info, idx) => (
              <div key={idx} className={styles.sysOptionRow}>
                <IconCurrentLocationGray />
                <p className={styles.sysOptTxt}>{info}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div ref={horizontalSwiperRef} className={styles.productSlideSec}>
        <HorizontalProductSwipe
          hasBulletsIcons
          defaultActiveSwiperIdx={defaultActiveSwiperIdx}
          slides={PRODUCT_SLIDES[enhancementsContentKey]}
        />
      </div>
      <div className={styles.section}>
        <SectionTitleLeftWaves title={translate("What Others Chose")} />
        <div className={styles.otherChoiceContent}>
          {randomProducts.map((item, idx) => (
            <ProductItem
              key={idx}
              name={item.device_name}
              description={item.details}
              content={item.image}
              forwardTo={`/product?name=${item.device_name}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
