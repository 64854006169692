import {
  IconStarFilled,
  IconDoubleTickFilled,
  IconDiamondFilled,
  IconSettingFilled,
  IconWavedLampFilled,
  IconPrecisionFilled,
} from "icons";

// Update the image imports to use the base URL from the environment variable
const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

export const EXISTING_MARKET_SECTOR_KEYS = {
  "Automotive/EV": "Automotive/EV",
  "automotive/ev": "automotive/ev",
  medical: "medical",
};

export const INFOS = {
  default: [
    "From battery management systems to power electronics and beyond, we offer comprehensive soldering services for a wide range of EV applications. Whether you require prototype development, low-volume production, or high-volume manufacturing, we have the capabilities and resources to accommodate your needs.",
    "As a trusted partner to leading EV manufacturers and suppliers, we are committed to excellence in every aspect of our work. With a focus on innovation, quality, and customer satisfaction, we strive to exceed expectations and drive success in the rapidly evolving EV market.",
    "Introducing Pillarhouse, your trusted partner in soldering excellence for the electric vehicle (EV) industry. As pioneers in soldering technology, we specialize in providing top-tier soldering services tailored specifically for EV manufacturers.",
  ],
  "automotive/ev": [
    "Introducing Pillarhouse, your trusted partner in soldering excellence for the automotive industry. As pioneers in soldering technology, we specialize in providing top-tier soldering services tailored specifically for manufacturers across a wide spectrum of automotive applications. From battery management systems to power electronics and beyond, we offer comprehensive soldering solutions to meet the diverse needs of automotive production.",
    "Whether you're embarking on prototype development, managing low-volume production, or scaling up for high-volume manufacturing, Pillarhouse has the capabilities and resources to effectively support your needs.",
    "As a valued partner to leading automotive manufacturers and suppliers, we are dedicated to achieving excellence in every facet of our operations. By prioritizing innovation, quality, and customer satisfaction, we aim not only to meet but exceed expectations in the dynamic and evolving automotive market.",
  ],
  medical: [
    "From implantable devices to diagnostic equipment and beyond, we offer comprehensive soldering services for a wide range of medical device applications. Whether you require prototype development, low-volume production, or high-volume manufacturing, we have the capabilities and resources to meet your needs with precision, control, and reliability.",
    "As a trusted partner to leading medical device manufacturers and suppliers, we are committed to excellence in every aspect of our work. With a focus on precision, meticulous tracking, and unwavering reliability, we strive to exceed expectations and drive success in the highly regulated medical device market.",
    "Introducing Pillarhouse, your trusted partner in soldering excellence for the medical device industry. As pioneers in soldering technology, we specialize in providing top-tier soldering services tailored specifically for medical device manufacturers, ensuring the highest standards of precision, control, and reliability.",
  ],
};

export const SYSTEM_OPTIONS = {
  default: [
    "Top side IR preheat, with closed-loop control",
    "Motorised underside IR preheat, at all cell positions",
    "Ultrasonic fluxing",
    "Dual Drop-Jet / Ultrasonic fluxing",
  ],
  // added for not get error
  "automotive/ev": [
    "Top side IR preheat, with closed-loop control",
    "Motorised underside IR preheat, at all cell positions",
    "Ultrasonic fluxing",
    "Dual Drop-Jet / Ultrasonic fluxing",
  ],
  medical: [
    "Top Side IR  Pre-Heat",
    "Closed-Loop Top Side Pyro Control",
    "Bottom Side Pre-Heat",
    "Closed-Loop Bottom Side Pyro Control",
    "Drop Jet -multiple heads",
    "Automatic Fiducial Correction",
    "PCB Warpage Correction",
    "1.2mm Micro Nozzle",
    "Thermal Nozzle Calibration",
    "Nozzle Verification",
  ],
};

export const MONITORING_OPTIONS = {
  default: [
    "Flux spray monitoring",
    "Flux flow monitoring",
    "Flux spray & flow monitoring",
  ],
  "automotive/ev": [
    "Flux spray monitoring",
    "Flux flow monitoring",
    "Flux spray & flow monitoring",
  ],
  medical: [
    "Optical Flux Presence Sensor",
    "Flux Flow Monitoring",
    "MES (Manufacturing Equipment Systems)",
    "Automatic rework Integration Internal Monitoring of Machine Temperature",
  ],
};

export const CHOOSE_US_ITEMS = {
  default: [
    {
      icon: <IconStarFilled />,
      title: "Expertise",
      text: "Experience meets innovation in Pillarhouse, your go-to partner for cutting-edge solutions in the selective soldering industry. With roots deeply embedded in the automotive sector, we've been trusted by major EV and traditional automakers worldwide.",
    },
    {
      icon: <IconPrecisionFilled />,
      title: "Precision",
      text: "Powered by state-of-the-art process control technology, we ensure a robust manufacturing process that stands the test of time, from inception to mature production.",
    },
    {
      icon: <IconDoubleTickFilled />,
      title: "Efficiency",
      text: "Efficiency is at the core of what we do. Whether you opt for our modular or cellular solutions, rest assured, we have the perfect streamlined system tailored to your exact requirements.",
    },
    {
      icon: <IconWavedLampFilled />,
      title: "Innovation",
      text: "At Pillarhouse, innovation isn't just a buzzword – it's our ethos. With 'Expect Innovation' as our tagline, we're dedicated to delivering on that promise. As the largest market share 'pure play' selective manufacturer, we channel all our resources into driving process advancements that set industry standards.",
    },
    {
      icon: <IconDiamondFilled />,
      title: "Quality",
      text: "With multiple factories and mature manufacturing processes, Pillarhouse guarantees the highest standard of machinery and processes, ensuring reliability and longevity.",
    },
    {
      icon: <IconSettingFilled />,
      title: "Customization",
      text: "With a keen ear to your needs, Pillarhouse offers tailored solutions that are both agile and creative. Explore our diverse range of platforms, each uniquely suited to elevate your industry standards. Trust Pillarhouse for unmatched expertise, precision, efficiency, innovation, quality, and customization – because your success is our priority.",
    },
  ],
  // added to not get error
  "automotive/ev": [
    {
      icon: <IconStarFilled />,
      title: "Expertise",
      text: "Experience meets innovation in Pillarhouse, your go-to partner for cutting-edge solutions in the selective soldering industry. With roots deeply embedded in the automotive sector, we've been trusted by major EV and traditional automakers worldwide.",
    },
    {
      icon: <IconPrecisionFilled />,
      title: "Precision",
      text: "Powered by state-of-the-art process control technology, we ensure a robust manufacturing process that stands the test of time, from inception to mature production.",
    },
    {
      icon: <IconDoubleTickFilled />,
      title: "Efficiency",
      text: "Efficiency is at the core of what we do. Whether you opt for our modular or cellular solutions, rest assured, we have the perfect streamlined system tailored to your exact requirements.",
    },
    {
      icon: <IconWavedLampFilled />,
      title: "Innovation",
      text: "At Pillarhouse, innovation isn't just a buzzword – it's our ethos. With 'Expect Innovation' as our tagline, we're dedicated to delivering on that promise. As the largest market share 'pure play' selective manufacturer, we channel all our resources into driving process advancements that set industry standards.",
    },
    {
      icon: <IconDiamondFilled />,
      title: "Quality",
      text: "With multiple factories and mature manufacturing processes, Pillarhouse guarantees the highest standard of machinery and processes, ensuring reliability and longevity.",
    },
    {
      icon: <IconSettingFilled />,
      title: "Customization",
      text: "With a keen ear to your needs, Pillarhouse offers tailored solutions that are both agile and creative. Explore our diverse range of platforms, each uniquely suited to elevate your industry standards. Trust Pillarhouse for unmatched expertise, precision, efficiency, innovation, quality, and customization – because your success is our priority.",
    },
  ],
  medical: [
    {
      icon: <IconStarFilled />,
      title: "Expertise",
      text: "Experience meets innovation in Pillarhouse, your go-to partner for cutting-edge solutions in the selective soldering industry. With roots deeply embedded in the automotive sector, we've been trusted by major EV and traditional automakers worldwide.",
    },
    {
      icon: <IconPrecisionFilled />,
      title: "Precision",
      text: "Powered by state-of-the-art process control technology, we ensure a robust manufacturing process that stands the test of time, from inception to mature production.",
    },
    {
      icon: <IconDoubleTickFilled />,
      title: "Efficiency",
      text: "Efficiency is at the core of what we do. Whether you opt for our modular or cellular solutions, rest assured, we have the perfect streamlined system tailored to your exact requirements.",
    },
    {
      icon: <IconWavedLampFilled />,
      title: "Innovation",
      text: "At Pillarhouse, innovation isn't just a buzzword – it's our ethos. With 'Expect Innovation' as our tagline, we're dedicated to delivering on that promise. As the largest market share 'pure play' selective manufacturer, we channel all our resources into driving process advancements that set industry standards.",
    },
    {
      icon: <IconDiamondFilled />,
      title: "Quality",
      text: "With multiple factories and mature manufacturing processes, Pillarhouse guarantees the highest standard of machinery and processes, ensuring reliability and longevity.",
    },
    {
      icon: <IconSettingFilled />,
      title: "Customization",
      text: "With a keen ear to your needs, Pillarhouse offers tailored solutions that are both agile and creative. Explore our diverse range of platforms, each uniquely suited to elevate your industry standards. Trust Pillarhouse for unmatched expertise, precision, efficiency, innovation, quality, and customization – because your success is our priority.",
    },
  ],
};

export const OTHER_MARKETS = [
  {
    name: "Medical",
    forwardTo: "/market?name=Medical",
    description:
      "Entry-level, benchtop, single point selective soldering system",
    content: `${IMAGE_BASE_URL}market-sector/medical.png`,
  },
  {
    name: "Aerospace",
    forwardTo: "/market?name=Aerospace",
    description:
      "Elementary, flexible, single point selective soldering system",
    content: `${IMAGE_BASE_URL}market-sector/aviation.png`,
  },
  {
    name: "Contract Manufacturing",
    forwardTo: "/market?name=Hi-Rel%2FInfrastructure",
    description:
      "Enhanced, entry-level, single point selective soldering system",
    content: `${IMAGE_BASE_URL}market-sector/contract-manufacturing.png`,
  },
];

export const RELATED_PRODUCTS = [
  {
    name: "Pilot",
    forwardTo: "/product?name=pilot",
    description:
      "Entry-level, benchtop, single point selective soldering system",
    content: `${IMAGE_BASE_URL}products/pilot.png`,
  },
  {
    name: "JADE MKII",
    forwardTo: "/product?name=JADE MKII",
    description:
      "Elementary, flexible, single point selective soldering system",
    content: `${IMAGE_BASE_URL}products/jade-mk2.png`,
  },
  {
    name: "QUADRON (PLC)",
    forwardTo: "/product?name=Quadron",
    description: "High specification, PLC, rotary soldering machine",
    content: `${IMAGE_BASE_URL}products/quadron-plc.png`,
  },
];

export const GENERAL_DESC_BY_KEY = {
  default:
    "Welcome to Pillarhouse, your premier partner in soldering solutions for the dynamic electric vehicle (EV) industry. With unparalleled expertise and cutting-edge technology, we specialize in providing high-quality soldering services tailored specifically to the unique demands of electric vehicle manufacturing.",
  "automotive/ev":
    "Welcome to Pillarhouse, your leading partner in soldering solutions for the dynamic automotive industry. With unmatched expertise and state-of-the-art technology, we specialize in delivering high-quality soldering services designed to meet the specialized demands of vehicle manufacturing, including electric vehicles and beyond.",
};
